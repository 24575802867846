import {Log, Util} from "@voxfp/opal_ui_common";
import {TemplateFamily} from "../model/templateFamily";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from '../utils/commonUtils';
import { Template } from "../model/template";

export class TemplateFamilyService {

    constructor () {
        OpalStateManager.observeList(OpalEntity.TemplateFamily, this.fetchTemplateFamilies);
    }

    fetchTemplateFamilies() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateFamily, null, null, true)
            .then((templateFamilies: TemplateFamily) => {
                resolve(templateFamilies);
            }).catch((error) => {
                Log.error("TemplateFamilyService:fetchTemplateFamilies", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getTemplateFamily(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateFamilyTemplates, id, null, true)
            .then(async (templates: Template) => {
                resolve(templates);
            }).catch((error) => {
                Log.error("TemplateFamilyService:getTemplateFamily", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
