import * as React from 'react';
import {PrimaryButton, TextField, Spinner, Dialog, DialogFooter, DialogType, MessageBar, MessageBarType} from 'office-ui-fabric-react';
import {OpalStoreService} from "@voxfp/opal_ui_common";
import {LoginApi as LoginApi, PermissionsApi} from '../services/http';
import {Log} from "@voxfp/opal_ui_common";
import logo from '../assets/images/logo.png';

export class Login extends React.Component<any, any> {

    constructor(props, context) {
        super(props, context);

        this.state = {
            username: '',
            password: '',
            loginChecking: false,
            hideLoginError: true
        };
    }

    login() {
        return new Promise((resolve, reject) => {
            this.setState({
                loginChecking: true
            });
            LoginApi.login({
                auth: {
                    login: this.state.username,
                    password: this.state.password
                }
            }).then((result: any) => {
                OpalStoreService.setAuthToken(result.jwt);
                OpalStoreService.setProperty('author', this.state.username);
                this.setState({ loggedIn: true });
                resolve(result);
                this.getPermissions().then(() => {
                    this.props.history.push('/');
                });
            }).catch((err: any) => {            
                this.setState({
                    hideLoginError: false,
                    loginChecking: false,
                    loggedIn: false
                });
                Log.error(err);
                reject(err);
            });
        });        
    }

    getPermissions() {
        return new Promise((resolve) => {
            let permissionJSON = [];
            PermissionsApi.getPermissions().then(permissions => {
                permissions.data.forEach(item => {
                    permissionJSON.push(item);
                });
                OpalStoreService.setProperty('permissions', permissionJSON);
                resolve(permissionJSON);
            }).catch((error) => {
                Log.error(error);
            });
        });
    }

    setStateProp(prop, value) {
        let state = {};
        state[prop] = value;
        this.setState(state);
    }

    closeDialogModal = () => {
        this.setState({ hideLoginError: true });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.login();
        }
    }

    render() {

        return (
            <div className='loginPage'>
                <section className='loginHeader'>
                    <img width='250' height='90' title='Opal Add-in' alt="Opal Logo" src={logo}/>
                </section>
                <div className='loginBox'>
                    <TextField
                        label='Username:'
                        name={'login'}
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => this.setStateProp('username', value)}
                        onKeyPress={this.handleKeyDown.bind(this)}
                        type={'text'}
                        value={this.state.username}
                    />
                    <TextField
                        label='Password:'
                        name={'password'}
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => this.setStateProp('password', value)}
                        onKeyPress={this.handleKeyDown.bind(this)}
                        type={'password'}
                        value={this.state.password}
                    />
                    <div className="loginButton">
                        {this.state && !this.state.loginChecking ?
                            <PrimaryButton styles={{flexContainer: { flexDirection: 'row-reverse' }}} text='Login' iconProps={{ iconName: "NavigateForward" }} onClick={ 
                                this.login.bind(this)
                            }/>
                            :
                            <PrimaryButton>
                                <Spinner />
                            </PrimaryButton>
                        }
                    </div>
                </div>

                <Dialog
                    hidden={this.state.hideLoginError}
                    onDismiss={ () => this.setState({hideLoginError: true})  }
                    dialogContentProps={ {
                        type: DialogType.normal,
                        title: 'Login Error'
                    } }
                    modalProps={ {
                        titleAriaId: 'ErrorLogin',
                        isBlocking: false
                    } }
                >
                    <MessageBar messageBarType={MessageBarType.error}>
                        The E-mail or Password you entered is incorrect. Please check and try again.
                    </MessageBar>
                    <DialogFooter>
                        <PrimaryButton onClick={ () => this.setState({hideLoginError: true}) } text='Ok' />
                    </DialogFooter>                    
                </Dialog>

            </div>
        );
    }
}
