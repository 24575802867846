import {Model} from '@voxfp/opal_ui_common';
import {Relationships} from "./relationships";

export class DocumentMetadata extends Model<DocumentMetadata> {

    documentName: string;
    documentContent: Array<any>;
    name: string;
    relationships: Relationships;

    constructor(documentName?: string, documentContent?: Array<any>, name?: string, relationships?: Relationships) {
        super(DocumentMetadata);
        this.documentName = documentName;
        this.documentContent = documentContent;
        this.name = name;
        this.relationships = relationships;
    }
}


