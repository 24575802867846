import {Util} from "@voxfp/opal_ui_common";

export class CommonUtils {

    static FormatError(error) {
        if (error instanceof OfficeExtension.Error) {
            error = "Word API " + error.debugInfo.message + ". " + error.debugInfo.errorLocation;
        }  
        else {
            try {
                let opalError = JSON.parse(error);
                if (opalError) {
                    error = opalError.errors[0].message;
                }
            }
            catch {
                return error;
            }
        }            
        return error;
    }

    static clearDocument() {        
        return new Promise((resolve) => {
            if (typeof Word !== 'undefined') {
                Word.run(async (context) => {
                    Util.startSpinning();
                    let sections = context.document.sections;
                    context.load(sections);
                    await context.sync();
                    sections.items.forEach((section) => {
                        section.body.clear();
                        section.getHeader('Primary').clear();
                        section.getFooter('Primary').clear();
                        section.getHeader('FirstPage').clear();
                        section.getFooter('FirstPage').clear();
                        section.getHeader('EvenPages').clear();
                        section.getFooter('EvenPages').clear();
                    }); 
                    resolve("Success");
                })
                .catch( (error) => {
                    console.log(this.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
            }
        });
    }
   
}

export default CommonUtils;
