import {Model} from "@voxfp/opal_ui_common";

export class SmartChart extends Model<SmartChart> {

    label: string;
    friendlyName: string;
    value?: string;
    ooxml?: string;

    constructor(label?: string, friendlyName?: string, value?: string, ooxml?: string) {
        super(SmartChart);
        this.label = label;
        this.friendlyName = friendlyName;
        this.value = value;
        this.ooxml = ooxml;
    }

}
