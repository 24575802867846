import {Log, Util} from "@voxfp/opal_ui_common";
import {ServiceObserver} from "./serviceObserver";
import {SmartRule} from "../model/smartRule";
import OpalStateManager, {OpalEntity} from "../state/stateManager";

export class SmartRuleService {

    private _templateId;
    private _smartRuleList = [];
    private _smartRuleListOptions = [];
    private _tokenTypeList: Array<any>;
    private _tokenTypeOptions: Array<any>;
    tokenTypeAttributeOptions: Array<any>;

    constructor (tokenKey: any) {
        this._templateId = tokenKey;
        this._smartRuleList = [];
        this._smartRuleListOptions = [];

        OpalStateManager.observeList(OpalEntity.TemplateSmartRule, this.fetchSmartRules);
    }

    fetchSmartRules() {
        Util.startSpinning();
        return OpalStateManager.fetchEntityList(OpalEntity.TemplateSmartRule, {tokenId: this._templateId})
            .then((data: any) => {
                this._smartRuleList = data;
                this._smartRuleListOptions = Util.orderOptions(data, 'id', 'name');
                ServiceObserver.trigger('SmartRuleService:fetchSmartRules', this.smartRuleList);
                Util.stopSpinning();
            }).catch(Log.error);
            
    }

    fetchTokenTypes() {
        return OpalStateManager.fetchEntityList(OpalEntity.TokenType)
            .then((data: any) => {
                let list = [];
                let items = data.data;
                items.forEach((i) => {
                    let item = i.attributes;
                    item.id = i.id;
                    list.push(item);
                });
                this._tokenTypeList = list;
                this._tokenTypeOptions = Util.orderOptions(data, 'id', 'name');
                ServiceObserver.trigger('SmartRuleService:fetchTokenTypes', this.tokenTypeList);
                return list;
            });
    }

    fetchTokenTypeAttributes(tokenTypeId) {
        return OpalStateManager.fetchEntity(OpalEntity.TokenType, tokenTypeId).then((data) => {
            let tokenType = data.data;
            let tokenTypeAttributeOptions = [];
            tokenType.attributes['possible-validators'].forEach((attribute) => {
                let found = tokenTypeAttributeOptions.findIndex(item => item.key === attribute.name ) >= 0;
                if (!found) {
                    tokenTypeAttributeOptions.push({
                        attributeId: attribute.id,
                        key: attribute.name,
                        text: attribute.name,
                        description: attribute.description,
                        value: ''
                    });
                }
            });
            this.tokenTypeAttributeOptions = tokenTypeAttributeOptions;
            ServiceObserver.trigger('SmartRuleService:fetchTokenTypeAttributes', this.tokenTypeAttributeOptions);
            return tokenTypeAttributeOptions;
        });
    }

    saveSmartRule(smartRule) {
        return OpalStateManager.createEntity(OpalEntity.TemplateSmartRule, smartRule, {tokenId: this._templateId})
        .then((smartRule: SmartRule) => {
            ServiceObserver.trigger('SmartRuleService:saveSmartRule', smartRule);
            return smartRule;
        }).catch(Log.error);
    }

    getSmartRule(id) {
        return OpalStateManager.fetchEntity(OpalEntity.SmartRule, id);
    }

    get smartRuleList(): any[] {
        return this._smartRuleList;
    }

    get smartRuleListOptions(): any[] {
        return this._smartRuleListOptions;
    }

    get tokenTypeList(): Array<any> {
        return this._tokenTypeList;
    }

    get tokenTypeOptions(): Array<any> {
        return this._tokenTypeOptions;
    }
}
