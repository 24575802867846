import * as React from 'react';
import {PrimaryButton, IBreadcrumbItem, MessageBarType} from "office-ui-fabric-react";
import {Header} from '../../components/header';
import {TemplateAndTemplateFamily} from "../../components/templateAndTemplateFamily";
import {Template} from "../../model/template";
import {TemplateService} from "../../services/templateService";
import {Util, Toast} from "@voxfp/opal_ui_common";
import {CommonUtils} from '../../utils/commonUtils';

export interface AppProps {
    title: string;
    config: object;
    history: any;
    match: any;
    permissions?: any;
}

export interface AppState {
    selectedTemplateFamily: any;
    selectedTemplate: any;
    previewButtonDisabled: boolean;
    formValid: boolean;
}

export class SelectTemplate extends React.Component<AppProps, AppState> {
    public breadcrumbList: Array<IBreadcrumbItem> = [];

    public templateService: TemplateService = new TemplateService();

    private permission; 

    constructor(props, state) {
        super(props, state);
        
        if (this.props.permissions) {
            this.permission = this.props.permissions.can("edit", "template");
        }
        
        this.breadcrumbList = [
            { text: 'Home', key: 'Home', onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                this.leavePage("/home");
            } },
            { text: this.permission ? 'Edit Template' : 'Select Template', key: 'f1', isCurrentItem: true}
        ];

        this.state = {
            selectedTemplateFamily: null,
            selectedTemplate: null,
            previewButtonDisabled: true,
            formValid: null
        };
    }

    leavePage(path) {
        this.props.history.push(path);       
    }

    handleProceed() {
        let isValid = this.validateForm();
        if (isValid) {
            this.displayTemplate();
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    navigateToEditTemplate() {
        localStorage.setItem("templateType", "existing");
        this.props.history.push('/editTemplate/' + this.state.selectedTemplate);
    }

    handleSelectTemplateFamily(option) {
        this.setState({
            selectedTemplateFamily: option,
            selectedTemplate: null
        });
    }

    handleSelectTemplate(option) {
        this.setState({selectedTemplate: option.key, previewButtonDisabled: false});
    }

    validateForm() {
        let validationCheck = this.state.selectedTemplateFamily > 0 && this.state.selectedTemplate > 0 ? true : false;
        this.setState({
            formValid: validationCheck
        });
        return validationCheck;       
    }

    displayTemplate() { 
        let templateId = this.state.selectedTemplate;        
        Util.startSpinning();
        this.templateService.getTemplate(templateId).then((template: Template) => {

            if (typeof Word !== 'undefined') {
                let ooxml = template.ooxml;
                Word.run(async (context) => {

                    context.document.properties.load('title');                    

                    let sections = context.document.sections;
                    context.load(sections);

                    await context.sync();

                    sections.items.forEach((section) => {
                        if (ooxml['body']) {
                            section.body.insertOoxml(ooxml['body'], "Replace");
                        }
                        else {
                            section.body.clear();
                        }
                        if (ooxml['primary-header']) {
                            section.getHeader('Primary').insertOoxml(ooxml['primary-header'], "Replace");
                        }
                        else {
                            section.getHeader('Primary').clear();
                        }
                        if (ooxml['primary-footer']) {
                            section.getFooter('Primary').insertOoxml(ooxml['primary-footer'], "Replace");
                        }
                        else {
                            section.getFooter('Primary').clear();
                        }                                              
                        if (ooxml['first-header']) {
                            section.getHeader('FirstPage').insertOoxml(ooxml['first-header'], "Replace");
                        }
                        else {
                            section.getHeader('FirstPage').clear();
                        }
                        if (ooxml['first-footer']) {
                            section.getFooter('FirstPage').insertOoxml(ooxml['first-footer'], "Replace");
                        }
                        else {
                            section.getFooter('FirstPage').clear();
                        }
                        if (ooxml['even-header']) {
                            section.getHeader('EvenPages').insertOoxml(ooxml['even-header'], "Replace");
                        }
                        else {
                            section.getHeader('EvenPages').clear();
                        }
                        if (ooxml['even-footer']) {
                            section.getFooter('EvenPages').insertOoxml(ooxml['even-footer'], "Replace");
                        }
                        else {
                            section.getFooter('EvenPages').clear();
                        }
                        
                    });

                    context.document.properties.set({title: template.name});
                    let contentControls = context.document.body.contentControls;                    
                    context.load(contentControls);

                    await context.sync();

                    contentControls.items.forEach((contentControl) => {
                        contentControl.appearance = 'Hidden';
                    });

                    await context.sync();
                            
                })
                .catch((error) => {
                    console.log(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
            } else {
                Util.stopSpinning();
            }
            if (this.permission) {
                this.navigateToEditTemplate();
            }

        }).catch((error) => {
            Util.showToast(new Toast(error, MessageBarType.error));
        });
    }

    render() {
        
        return (
            <div className='ms-navigation__main'>
                <Header match={this.props.match} message='' history={this.props.history} breadcrumbLinks={this.breadcrumbList} permissions={this.props.permissions} />
                <section className='ms-navigation__content p1 ms-font-m ms-fontColor-neutralPrimary'>
                    <TemplateAndTemplateFamily 
                        onChangeTemplateFamily={this.handleSelectTemplateFamily.bind(this)}
                        onChangeTemplate={this.handleSelectTemplate.bind(this)}
                        selectedTemplateFamily={this.state && this.state.selectedTemplateFamily}
                        selectedTemplate={this.state && this.state.selectedTemplate}  
                        formValid={this.state && this.state.formValid}
                    />
                    <br/>
                    <PrimaryButton text={this.permission ? 'Edit Template' : 'View Template'} disabled={this.state && this.state.selectedTemplate < 1} onClick={this.handleProceed.bind(this)} />
                </section>
               
            </div>
        );
    }

}
