import * as React from 'react';
import {MessageBarType} from "office-ui-fabric-react";
import {TemplateFamilyList} from "./templateFamiliyList";
import {OpalStoreService} from "@voxfp/opal_ui_common";
import {Util, Toast} from "@voxfp/opal_ui_common";
import {TemplateService} from "../services/templateService";
import {TemplateFamilyService} from "../services/templateFamilyService";
import {OpalDropdownList} from "./opalDropdownList/opalDropdownList";

export interface Props {
    selectedTemplateFamily?: any;
    selectedTemplate?: any;
    onChangeTemplateFamily?: any;
    onChangeTemplate?: any;
    data?: any;
    formValid?: boolean;
}

export interface State {
    templates: Array<any>;
    fetching: boolean;
}

export class TemplateAndTemplateFamily extends React.Component<Props, State> {

    templateFamilyService: TemplateFamilyService = new TemplateFamilyService();
    templateService: TemplateService = new TemplateService();

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
    }

    populateTemplates(templateFamilyId) {
        this.templateFamilyService.getTemplateFamily(templateFamilyId)
        .then((templates) => {
            this.setState({ 
                templates: Util.orderOptions(templates, 'id', 'name') 
            });
        }).catch((_error) => {
            Util.showToast(new Toast("error", MessageBarType.error));
        }).finally(() => {
            this.setState({
                fetching: false
            });
        });
    }

    handleTemplateFamilyChange(option) {
        let templateFamilyId = option.key;
        this.setState({
            fetching: true
        });
        this.populateTemplates(templateFamilyId);
        
        if (this.props.onChangeTemplateFamily) {
            this.props.onChangeTemplateFamily(templateFamilyId);
        }
    }

    handleTemplateChange(option) {
        OpalStoreService.setCurrentTemplateId(option.key);

        if (this.props.onChangeTemplate) {
            this.props.onChangeTemplate(option);
        }
    }

    render() {
        return (
            <div>
                <TemplateFamilyList 
                    onChangeTemplateFamily={this.handleTemplateFamilyChange.bind(this)}
                    selectedTemplateFamily={this.props && this.props.selectedTemplateFamily} 
                    formValid={this.props && this.props.formValid}
                />

                {this.state && this.state.templates && this.state.fetching === false &&
                    <div className="mb1">
                        <OpalDropdownList
                            label="Template"
                            allowFiltering={true}
                            items={this.state.templates}
                            onChange={(item) => { this.handleTemplateChange(item); }}
                            formValid={this.props && this.props.selectedTemplate < 1 && this.props.formValid === false ? false : true}
                            currentItem={this.props && this.props.selectedTemplate}
                        />
                    </div>
                }

            </div>
        );
    }
}
