import * as React from "react";
import {MessageBarType, Dialog, DialogFooter, PrimaryButton, DefaultButton, MessageBar, DialogType} from "office-ui-fabric-react";
import {SmartTokenService} from "../../services/smartTokenService";
import {TemplateService} from "../../services/templateService";
import {SmartToken} from "../../model/smartToken";
import {Log, Util, Toast} from "@voxfp/opal_ui_common";
import {OpalDropdownList} from "../opalDropdownList/opalDropdownList";

export interface Props {
    title?: string;
    templateKey: any;
    onInsertToken?: any;
    onCancel?: any;
    playbookId?: number;
}

export interface State {
    tokenOptions: Array<any>;
    currentToken: SmartToken;
    formValid: boolean;
    errorMessage: boolean;
}

const initialState = {
    visible: true,
    tokenOptions: null,
    currentToken: new SmartToken(),
    formValid: null,
    errorMessage: false
};

export class SelectToken extends React.Component<Props, State> {
    constructor(props, state) {
        super(props, state);
        this.state = initialState;
    }

    smartTokenService: SmartTokenService = new SmartTokenService();
    templateService: TemplateService = new TemplateService();

    componentDidMount() {
        this.resetState();
        this.fetchSmartTokens();
    }

    fetchSmartTokens() {
        this.smartTokenService.fetchSmartTokens(this.props.playbookId).then((data) => {
            let smartTokenListOptions: Array<any> = [];
            smartTokenListOptions = Util.orderOptions(data, 'id', 'label');
            this.setState({
                tokenOptions: smartTokenListOptions
            });
        }).catch(error => {     
            Log.error("SmartTokenService:fetchSmartTokens", error);
            this.setState({
                errorMessage: true
            });
            Util.showToast(new Toast('Error fetching Tokens.', MessageBarType.error, 30000));
        });
    }

    handleInsert() {
        let isValid = this.validateForm();
        if (isValid) {
            this.smartTokenService.insertToken(this.state.currentToken)
            .then((smartToken) => {
                this.props.onInsertToken(smartToken);
                this.resetState();
                this.fetchSmartTokens();
            }).catch((error) => {                
                Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
            });
            return true;
        }
        else {            
            Util.showToast(new Toast('Please select an item.', MessageBarType.error));
            return false;
        }
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleTokenChange(item) {
        this.smartTokenService.getSmartToken(item.key)
        .then((smartToken: any) => {
            this.setState({
                currentToken: smartToken
            });
        })
        .catch((error) => {                
            Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
        });
    }

    validateForm() {
        if (this.state.currentToken !== null && this.state.currentToken.id > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }
    
    resetState() {
        this.setState({
            formValid: null,
            errorMessage: false
        });
    }
    
    render() {
        return (

            <Dialog
                hidden={this.state && this.state.tokenOptions || this.state && this.state.errorMessage ? false : true}
                dialogContentProps={{
                    title: 'Insert Token',
                    type: DialogType.largeHeader
                }}
                modalProps={{
                    forceFocusInsideTrap: false
                }}
                >
                {this.state && this.state.tokenOptions &&

                    <OpalDropdownList 
                        label="Token"
                        allowFiltering={true}
                        items={this.state.tokenOptions}
                        onChange={(item) => { this.handleTokenChange(item); }}
                        formValid={this.state && this.state.currentToken === null && this.state.formValid === false ? false : true} 
                        currentItem={this.state && this.state.currentToken}
                    />

                }
                {this.state && this.state.errorMessage &&
                    <MessageBar>No items to show.</MessageBar>
                }
                <br/>
                {this.state && this.state.currentToken && this.state.currentToken.smartRule && this.state.currentToken.smartRule.name && this.state.currentToken.smartRule.description &&
                    <div className="mb2" hidden={!this.state || !this.state.currentToken}>
                        <label>
                            <span><strong>Rule: </strong>{this.state.currentToken.smartRule.name}</span>
                            <br />
                            <span><strong>Rule Description: </strong>{this.state.currentToken.smartRule.description}</span>
                        </label>
                    </div>
                }
                
                <DialogFooter>
                    <DefaultButton onClick={this.handleCancel.bind(this)} text="Cancel" />
                    {this.state && this.state.tokenOptions &&
                        <PrimaryButton onClick={this.handleInsert.bind(this)} text="Insert" />
                    }
                </DialogFooter>
            </Dialog>
      
        );
    }
}
