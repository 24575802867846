import * as React from 'react';
import {DefaultButton, PrimaryButton, Dialog, DialogFooter, DialogType} from "office-ui-fabric-react";

export interface Props {
    visible?: boolean;
    showOK?: boolean;
    canCancel?: boolean;
    showPrev?: boolean;
    showNext?: boolean;
    onCancel?: any;
    onPrev?: any;
    onNext?: any;
    onOK?: any;
    okLabel?: string;
    cancelLabel?: string;
    prevLabel?: string;
    nextLabel?: string;
    title?: string;
    text: string;
    content?: any;
    always?: any;
    resetSate?: boolean;
    displayClose?: boolean;
}

export interface State {
    visible: boolean;
}

export class OpalDialog extends React.Component<Props, State> {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.setState({
           visible: false
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible
            });
        }
    }

    dismissDialog() {
        this.setState({
            visible: false
        });
    }

    handleOk() {
        if (this.props.onOK) {
            this.props.onOK();
        } else {
            this.dismissDialog();
        }
        if (this.props.always) {
            this.props.always();
        }
    }

    handleCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.dismissDialog();
        }
        if (this.props.always) {
            this.props.always();
        }
    }

    handlePrev() {
        if (this.props.onPrev) {
            this.props.onPrev();
        }
    }

    handleNext() {
        if (this.props.onNext) {
            this.props.onNext();
        }
    }

    handleClose() {
        this.dismissDialog();
        if (this.props.always) {
            this.props.always();
        }
    }

    render() {
        return (
            <Dialog
                onDismiss={ () => { this.handleClose(); } }
                hidden={ !this.state || (this.state && !this.state.visible) }
                dialogContentProps={ {
                    type: DialogType.normal,
                    title: this.props.title,
                    subText: this.props.text,
                    showCloseButton: this.props.displayClose !== undefined ? this.props.displayClose : true
                }}
                modalProps={ {
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}>
                {this.props.content}
                <DialogFooter>
                    {this.props.canCancel &&
                        <DefaultButton className="pullleft" onClick={ this.handleCancel.bind(this)}
                                   text={this.props.cancelLabel || 'Cancel'}/>
                    }
                    {this.props.showPrev &&
                        <DefaultButton className="pullleft" onClick={ this.handlePrev.bind(this)}
                                   text={this.props.prevLabel || 'Back'}/>
                    }
                    {this.props.showNext &&
                        <PrimaryButton onClick={ this.handleNext.bind(this)}
                                   text={this.props.nextLabel || 'Next'}/>
                    || 
                    <PrimaryButton onClick={ this.handleOk.bind(this) }
                                   text={this.props.okLabel || 'OK'}/>
                    }
                </DialogFooter>
            </Dialog>
        );
    }
}
