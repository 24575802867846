import * as React from "react";
import {Dialog, DialogFooter, PrimaryButton, DefaultButton, MessageBar, MessageBarType, DialogType} from "office-ui-fabric-react";
import {SmartConditionService} from "../../services/conditionalService";
import {Log, Util, Toast} from "@voxfp/opal_ui_common";
import {OpalDropdownList} from "../opalDropdownList/opalDropdownList";

export interface Props {
    title?: string;
    onInsertSmartCondition?: any;
    templateKey: number;
    onCancel: any;
    playbookId?: number;
}

export interface State {
    currentConditional: number;
    conditionalOptions: Array<any>;
    errorMessage: boolean;
    formValid: boolean;
}

export class InsertSmartConditionDialog extends React.Component<Props, State> {

    conditionalService: SmartConditionService = new SmartConditionService(this.props.templateKey);

    componentDidMount() {
        this.resetState();

        this.conditionalService.fetchSmartConditions(this.props.playbookId).then((data) => {
            let conditionListOptions: Array<any> = [];
            conditionListOptions = Util.orderOptions(data, 'id', 'label');
            this.setState({
                conditionalOptions: conditionListOptions
            });
            this.setState(prevState => ({
                conditionalOptions: [ {key: 0, text: "Select a Condition", disabled: true}, ...prevState.conditionalOptions]
            }));
        }).catch(error => {     
            Log.error("SmartConditionService:fetchSmartConditions", error);
            this.setState({
                errorMessage: true
            });
            Util.showToast(new Toast('Error fetching Conditions.', MessageBarType.error));
        });
    }

    handleInsert() {
        let isValid = this.validateForm();
        if (isValid) {
            this.conditionalService.markSmartCondition(this.state.currentConditional)
            .then((smartCondition) => {
                this.props.onInsertSmartCondition(smartCondition);
                this.resetState();
            }).catch((error) => {                
                Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
            });
            return true;
        }
        else {            
            Util.showToast(new Toast('Please select an item.', MessageBarType.error));
            return false;
        }
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleConditionChange(option) {
        this.setState({
            currentConditional: option.key
        });
    }

    validateForm() {
        if (this.state.currentConditional > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    resetState() {
        this.setState({
            errorMessage: false,
            formValid: null
        });
    }
    
    render() {
        return (
            <Dialog
                hidden={this.state && this.state.conditionalOptions || this.state && this.state.errorMessage ? false : true}
                dialogContentProps={{
                    title: 'Insert Condition',
                    type: DialogType.largeHeader
                }}
                modalProps={{
                    forceFocusInsideTrap: false
                }}
            >
                {this.state && this.state.conditionalOptions &&
                    
                    <OpalDropdownList
                        label="Condition"
                        allowFiltering={true}
                        items={this.state.conditionalOptions}
                        onChange={(item) => { this.handleConditionChange(item); }}
                        formValid={this.state && this.state.currentConditional === null && this.state.formValid === false ? false : true}
                        currentItem={this.state && this.state.currentConditional}
                    />
                    
                }
                {this.state && this.state.errorMessage &&
                    <MessageBar>No items to show. Ensure the Playbook this template is in has Conditions assigned to it.</MessageBar>
                }
                <DialogFooter>
                    <DefaultButton onClick={this.handleCancel.bind(this)} text="Cancel" />
                    {this.state && this.state.conditionalOptions &&
                        <PrimaryButton onClick={this.handleInsert.bind(this)} text="Insert" />
                    }
                </DialogFooter>
            </Dialog>
        );
    }
}
