import * as React from 'react';
import {Header} from '../components/header';
import {OpalStoreService} from "@voxfp/opal_ui_common";
import {Menu} from '../components/menu';

export interface AppProps {
    title: string;
    config: object;
    history: any;
    visible: boolean;
    match: any;
    permissions?: any;
}

export interface AppState {
}

export class Home extends React.Component<AppProps, AppState>  {

    visible: boolean = true;
    contentControls = [];

    constructor(props, state) {
        super(props, state);
    }

    componentDidMount() {
        OpalStoreService.cleanFlashProperties();
    }

    render() {

        return (
            <div className='ms-navigation__main' >
                <Header message='' history={this.props.history} match={this.props.match} permissions={this.props.permissions} />
                <section className='ms-bgColor-neutralLighterAlt side-menu p1 open'>
                    <Menu history={this.props.history} permissions={this.props.permissions} />                    
                </section>
            </div>
        );
    }
}
