import {Model} from "@voxfp/opal_ui_common";

export class SmartCondition extends Model<SmartCondition> {

    id?: number;
    name?: string;
    label?: string;
    friendlyName?: string;
    description?: string;
    expression?: string;
    contentControl?: any;
    ooxml?: any;

    constructor(id?: number, name?: string, label?: string, description?: string, friendlyName?: string, expression?: string, contentControl?: any, ooxml?: any) {
        super(SmartCondition);
        this.id = id;
        this.name = name;
        this.label = label;
        this.friendlyName = friendlyName;
        this.description = description;
        this.expression = expression;
        this.contentControl = contentControl;
        this.ooxml = ooxml;
    }
}
