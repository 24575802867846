import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartSwitch} from "../model/switches";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartSwitchService {

    constructor () {
        OpalStateManager.observeList(OpalEntity.SmartSwitch, this.fetchSmartSwitches);
    }

    fetchSmartSwitches(playbookId) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookSmartSwitches, playbookId, null, true)
            .then((data: Array<SmartSwitch>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("SmartSwitchService:fetchSmartSwitches", error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartSwitch(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartSwitch, id)
            .then((smartSwitch: SmartSwitch) => {
                resolve(smartSwitch);
            }).catch(error => {            
                Log.error("switchService:getSmartSwitch", error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    markSmartSwitch(id) {
        return new Promise((resolve, reject) => {
            this.getSmartSwitch(id)
            .then((smartSwitch) => {
                this.insertSmartSwitch(smartSwitch)
                .then((insertedSwitch) => {
                    resolve(insertedSwitch);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    insertSmartSwitch(smartSwitch) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            if (typeof Word !== 'undefined') {
                Word.run(async (context) => {
                    let selection = context.document.getSelection();
                    let contentControl = selection.parentContentControlOrNullObject;
                    await context.sync();
                    context.load(contentControl);
                    context.load(selection);
                    if (contentControl.isNullObject) {
                        selection.insertOoxml(smartSwitch.ooxml, Word.InsertLocation.replace);
                    }
                    else {
                        reject("Error: Cannot insert insert a content control inside another content control.");
                    }
                    return context.sync().then(() => {
                        resolve(smartSwitch);
                    }); 
                }).catch((error) => {
                    Log.error("switchService:insertSmartSwitch", error);
                    reject(CommonUtils.FormatError(error)); 
                }).finally(() => {
                    Util.stopSpinning();
                });
            }
        });
    }
}
