import * as React from 'react';
import * as PropTypes from 'prop-types';
import {MessageBar, Spinner, SpinnerSize, Breadcrumb} from "office-ui-fabric-react";
import {Toast} from "@voxfp/opal_ui_common";
import {OpalStoreService} from "@voxfp/opal_ui_common";
import {SideMenu} from "./sidemenu";
import logo from '../assets/images/logo.png';

export interface HeaderProps {
    message: string;
    history: any;
    match?: any;
    breadcrumbLinks?: any;
    unsavedChanges?: boolean;
    permissions?: any;
}

export interface HeaderState {
    toast: Toast;
    spin: boolean;
}

export class SpinnerContainer extends React.Component<HeaderProps, HeaderState> {
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        OpalStoreService.Observer.observe('spin', (spin) => {
            try {
                if (this._isMounted === true) {
                    this.setState({
                        spin: spin
                    });
                }
            } catch (error) { }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            this.state && this.state.spin &&
            <div className="spinnerOverlay">
                <Spinner size={SpinnerSize.large} label='Working...' ariaLive='assertive'/>
            </div>
        );
    }
}

export class ToastContainer extends React.Component<HeaderProps, HeaderState> {
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        OpalStoreService.Observer.observe('toast', (toast) => {
            try {
                if (this._isMounted === true) {
                    this.setState({
                        toast: toast
                    });
                }
            } catch (error) { }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            this.state && this.state.toast && this.state.toast.visible &&
            <div className="messageBar-customPosition">
                <MessageBar
                    messageBarType={this.state && this.state.toast && this.state.toast.type}
                    isMultiline={false}
                >{this.state && this.state.toast && this.state.toast.message}
                </MessageBar>
            </div>
        );
    }
}

export class Header extends React.Component<HeaderProps, HeaderState> {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);
    }
    static contextTypes = {
        router:  PropTypes.object
    };

    componentDidMount() {
        this._isMounted = true;
        if (!OpalStoreService.getAuthToken() && this.props.history && this.props.history.push) {
            this.props.history.push('/login');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                <section className='nav ms-navigation__header ms-bgColor-neutralLighter ms-borderColor-neutralQuaternaryAlt' style={{width: '100%'}}>
                    {this.props && this.props.match.path !== "/" &&
                        <SideMenu history={this.props.history} match={this.props.match} unsavedChanges={this.props.unsavedChanges || false} permissions={this.props.permissions} />
                    }
                    <div className={this.props && this.props.match.path !== "/" ? 'pullright' : ''}>
                        <img className="logo m1" title='Opal Add-in' alt="Opal Logo" src={logo}/>
                    </div>
                </section>
                {this.props.message}
                <ToastContainer message="" history={this.props.history}/>
                <SpinnerContainer message="" history={this.props.history}/>
                <div className={"px05 breadcrumb"}>
                    <Breadcrumb
                        items={this.props.breadcrumbLinks}
                        maxDisplayedItems={3}
                        ariaLabel="Breadcrumb with items rendered as links"
                        overflowAriaLabel="More links"
                    />
                </div>
            </div>
        );
    }
}
