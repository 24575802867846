import {Model} from "@voxfp/opal_ui_common";
import {SmartRule} from "./smartRule";

export class SmartToken extends Model<SmartToken> {

    label: string;
    friendlyName: string;
    value?: string;
    smartRule: SmartRule;
    ooxml?: string;

    constructor(label?: string, friendlyName?: string, value?: string, ooxml?: string) {
        super(SmartToken);
        this.label = label;
        this.friendlyName = friendlyName;
        this.value = value;
        this.ooxml = ooxml;
    }

}
