import {Model} from "@voxfp/opal_ui_common";

export class DocumentRevision extends Model<DocumentRevision> {

    author: string;
    ooxml: string;
    updated_at: string;
    document_id: number;

    constructor(author?: string, ooxml?: string, updatedAt?: string, documentId?: number) {
        super(DocumentRevision);
        this.author = author;
        this.ooxml = ooxml;
        this.updated_at = updatedAt;
        this.document_id = documentId;
    }
}
