import {Model} from "@voxfp/opal_ui_common";

export class SmartDataValue extends Model<SmartDataValue> {

    lookupValue: string;
    lookupName: string;
    dataValue: string;
    category: string;

    constructor(lookupValue?: string, dataValue?: string, category?: string, lookupName?: string) {
        super(SmartDataValue);
        this.lookupValue = lookupValue;
        this.lookupName = lookupName;
        this.dataValue = dataValue;
        this.category = category;
    }

    toServerType(obj: SmartDataValue) {

        let clone = JSON.parse(JSON.stringify(obj));
        clone['lookup_value'] = obj.lookupValue;
        delete obj.lookupValue;
        clone['lookup_name'] = obj.lookupName;
        delete obj.lookupName;
        clone['data_value'] = obj.dataValue;
        delete obj.dataValue;

        return clone;
    }

    fromServerType(obj): SmartDataValue {

        obj.attributes.lookupValue = obj.attributes['lookup-value'];
        delete obj.attributes['lookup-value'];
        obj.attributes.lookupName = obj.attributes['lookup-name'];
        delete obj.attributes['lookup-name'];
        obj.attributes.dataValue = obj.attributes['data-value'];
        delete obj.attributes['data-value'];
        let newObj = obj.attributes as SmartDataValue;
        newObj.id = obj.id;
        return newObj;
    }
}
