import {Log, Util} from "@voxfp/opal_ui_common";
import {Template} from "../model/template";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from '../utils/commonUtils';

export class TemplateService {

    getTemplate(id) {        
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.Template, id, null, true)
            .then((template: Template) => {
                resolve(template);
            }).catch((error) => {
                Log.error("templateService:getTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    saveTemplate(template) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            let saveTemplate = null;
            if (template.id) {
                saveTemplate = OpalStateManager.updateEntity(OpalEntity.Template, template.id, template);
            }
            else {
                saveTemplate = OpalStateManager.createEntity(OpalEntity.Template, template);
            }
            return saveTemplate.then((template: Template) => {
                resolve(template);
            })
            .catch((error) => {
                Log.error("templateService:saveTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });            
        });
    }

    copyTemplate(template, templateToCopyKey) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.TemplateCopy, template, {templateId: templateToCopyKey}).then((template: Template) => {
                resolve(template);
            })
            .catch((error) => {
                Log.error("templateService:copyTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });            
        });
    }
}
