import * as React from 'react';
import {MessageBarType} from "office-ui-fabric-react";
import {Util, Toast} from "@voxfp/opal_ui_common";
import {TemplateFamilyService} from "../services/templateFamilyService";
import {OpalDropdownList} from "./opalDropdownList/opalDropdownList";

export interface Props {
    selectedTemplateFamily?: any;
    onChangeTemplateFamily: any;
    formValid?: boolean;
}

export interface State {
    selectedTemplateFamily: any;
    templateFamilies: Array<any>;
}

export class TemplateFamilyList extends React.Component<Props, State> {

    templateFamilyService: TemplateFamilyService = new TemplateFamilyService();

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.fetchTemplateFamilies();
    }

    fetchTemplateFamilies() {
        this.templateFamilyService.fetchTemplateFamilies()
        .then((templateFamilies) => {
            this.setState({ 
                templateFamilies: Util.orderOptions(templateFamilies, 'id', 'name') 
            });
        }).catch((error) => {
            Util.showToast(new Toast(error, MessageBarType.error));
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedTemplateFamily !== this.props.selectedTemplateFamily) {
            this.setState({
                selectedTemplateFamily: this.props.selectedTemplateFamily
            });
        }
    }

    handleTemplateFamilyChange(option) {
        this.setState({
           selectedTemplateFamily: option.key
        });
        if (this.props.onChangeTemplateFamily) {
            this.props.onChangeTemplateFamily(option);
        }
    }

    render() {
        return (
            this.state && this.state.templateFamilies &&
            <div className="mb1">
                <OpalDropdownList 
                    label="Template Family"
                    allowFiltering={true}
                    items={this.state && this.state.templateFamilies}
                    onChange={(item) => { this.handleTemplateFamilyChange(item); }}
                    formValid={this.props.formValid === false ? false : true}
                    currentItem={this.props && this.props.selectedTemplateFamily}
                />
            </div>
        );
    }
}
