import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartToken} from "../model/smartToken";
import {IDropdownOption} from "office-ui-fabric-react";
import {SmartRuleService} from "./smartRuleService";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";


export class SmartTokenService {

    public tokenList: Array<SmartToken> = [];
    public tokenListOptions: Array<IDropdownOption> = [];

    constructor () {
        this.tokenList = [];
        this.tokenListOptions = [];

        OpalStateManager.observeList(OpalEntity.TemplateToken, this.fetchSmartTokens);
    }

    fetchSmartTokens(playbookId?) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            let id = playbookId ? playbookId : null;
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookToken, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("SmartTokenService:fetchSmartTokens", error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartToken(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.Token, id)
            .then(async (token: SmartToken) => {
                token.smartRule = await new SmartRuleService(token.id).getSmartRule(token.smartRule.id);
                resolve(token);
            }).catch(error => {            
                Log.error("SmartTokenService:getSmartToken", error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    insertToken(token) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            Word.run(async (context) => {
                let selection = context.document.getSelection();
                let contentControl = selection.parentContentControlOrNullObject;
                await context.sync();
                context.load(contentControl);
                context.load(selection);
                if (contentControl.isNullObject) {
                    selection.insertOoxml(token.ooxml, Word.InsertLocation.replace);
                }
                else {
                    reject("Error: Cannot insert insert a content control inside another content control.");
                }
                return context.sync().then(() => {
                    resolve(token);
                });              
            }).catch((error) => {
                Log.error("SmartTokenService:insertToken", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
