import {Model} from "@voxfp/opal_ui_common";
import {Relationships} from "./relationships";
import {Playbook} from "./playbook";

export class TemplateFamily extends Model<TemplateFamily> {

    name: string;
    description: string;
    relationships: Relationships;
    playbook: Playbook;

    constructor(name?: string, description?: string, relationships?: Relationships, playbook?: Playbook) {
        super(TemplateFamily);
        this.name = name;
        this.description = description;
        this.relationships = relationships;
        this.playbook = playbook;
    }
}
