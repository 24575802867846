import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {Nav, ActionButton, DefaultButton, PrimaryButton, Dialog, DialogFooter, DialogType, INavLinkGroup, INavLink} from 'office-ui-fabric-react';
import {OpalStoreService} from '@voxfp/opal_ui_common';
import {TemplateService} from "../services/templateService";
import {CommonUtils} from "../utils/commonUtils";

function _onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
    return <h3 className="pb1 menu-header ms-borderColor-neutralQuaternaryAlt">{group.name}</h3>;
}

export interface MenuProps {
  history: any;
  unsavedChanges?: boolean;
  permissions?: any;
}

export interface MenuState {
    showLogoutDialog: boolean;
    displayChangesWarning: boolean;
    leavePath: any;
}

export class Menu extends React.Component<MenuProps, MenuState> {

    public templateService: TemplateService = new TemplateService();
    private canCreate;
    private canEdit;

    constructor(props, context) {
        super(props, context);
        this.state = {
            showLogoutDialog: false,
            displayChangesWarning: false,
            leavePath: null
        };
    }

    showLogoutDialog() {
        this.setState({ showLogoutDialog: true });
    }

    logout() {
        //CommonUtils.clearDocument();
        OpalStoreService.setAuthToken('');
        OpalStoreService.setProperty('author', '');
        OpalStoreService.setProperty('permissions', '');
        
        this.setState({ showLogoutDialog: false});
        this.props.history.push('/login');
    }
    
    hideLogoutDialog() {
        this.setState({ showLogoutDialog: false });
    }

    displayChangesWarningDialog(path) {
        this.setState({
            displayChangesWarning: true,
            leavePath: path
        });
    }

    hideChangesWarningDialog() {
        this.setState({
            displayChangesWarning: false
        });
    }

    leavePage(path) {
        let pathEnd = path.substr(path.lastIndexOf('/') + 1);
        CommonUtils.clearDocument().then(() => {
            localStorage.removeItem("templateType");
            if (pathEnd === "revisions") {
                this.props.history.push("/");
                Office.context.ui.displayDialogAsync(encodeURI(path), {height: 60, width: 50, displayInIframe: false, promptBeforeOpen: true});
            }
            else {
                this.props.history.push(path);
            }
        });
    }

    render() {
        if (this.props.permissions) {
            this.canCreate = this.props.permissions.can("create", "template");
            this.canEdit = this.props.permissions.can("edit", "template");
        }
        let unsavedChanges = this.props.unsavedChanges || false;
        const OpalNav = withRouter(({ history }) => (
            <Nav
                onLinkClick={(event, element) => {
                    if (event && element) {
                        event.preventDefault();
                        history.push(element.url);
                    }
                }}
                onRenderGroupHeader={_onRenderGroupHeader}
                groups={[
                    {
                        name: 'Templates',
                        links: [
                            {
                                name: 'New',
                                url: '',
                                icon: 'Add',
                                hidden: !this.canCreate,
                                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                    if (unsavedChanges) {
                                        this.displayChangesWarningDialog('/newTemplate');
                                    }
                                    else {
                                        this.props.history.push('/newTemplate');
                                    }
                                }
                            },
                            {
                                name: this.canEdit ? 'Edit' : 'View',
                                url: '',
                                icon: this.canEdit ? 'Edit' : 'WordDocument',
                                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                    if (unsavedChanges) {
                                        this.displayChangesWarningDialog('/selectTemplate');
                                    }
                                    else {
                                        this.props.history.push('/selectTemplate');
                                    }
                                }
                            },
                            {
                                name: 'Copy',
                                url: '',
                                icon: 'Copy',
                                hidden: !this.canCreate,
                                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                    if (unsavedChanges) {
                                        this.displayChangesWarningDialog('/copyTemplate');
                                    }
                                    else {
                                        this.props.history.push('/copyTemplate');
                                    }
                                }
                            }
                        ]
                    }
                ]}
            />
        ));
        return <div>
            <OpalNav />
            <div className="btn-logout ms-borderColor-neutralQuaternaryAlt">
                <ActionButton text="Logout" iconProps={{ iconName: "SignOut" }} className="ms-bgColor-neutralLighter--hover" onClick={() => this.showLogoutDialog()}></ActionButton>
            </div>
            <Dialog
                hidden={ !this.state.showLogoutDialog }
                onDismiss={ this.hideLogoutDialog.bind(this) }
                dialogContentProps={ {
                    type: DialogType.normal,
                    title: 'Logout',
                    subText: this.props.unsavedChanges ? 'You have unsaved changes. ' : null
                } }
                modalProps={ {
                    titleAriaId: 'myLabelId',
                    subtitleAriaId: 'mySubTextId',
                    isBlocking: false
                } }
            >
                Are you sure you want to logout from your account?
                <DialogFooter>
                    <DefaultButton onClick={ this.hideLogoutDialog.bind(this) } text='Cancel' />
                    <PrimaryButton onClick={ this.logout.bind(this) } text='Logout' />
                </DialogFooter>
                
            </Dialog>

            <Dialog
                hidden={ !this.state.displayChangesWarning }
                onDismiss={ this.hideChangesWarningDialog.bind(this) }
                dialogContentProps={ {
                    title: 'Unsaved Changes',
                    subText: 'You have unsaved changes. Are you sure you want to leave this page?'
                }}
            >
                <DialogFooter>
                    <DefaultButton onClick={ this.hideChangesWarningDialog.bind(this) } text='Stay' />
                    <PrimaryButton onClick={ () => {this.leavePage(this.state.leavePath); }} text='Leave this page' />
                </DialogFooter>                
            </Dialog>
        </div>;
    }
}
