import {Model} from '@voxfp/opal_ui_common';

export class Document extends Model<Document> {

    name: string;
    author: string;
    state: string;
    templateName: string;
    templateId: number;
    updatedAt: string;

    constructor(name?: string, author?: string, state?: string, templateName?: string, templateId?: number, updatedAt?: string) {
        super(Document);
        this.name = name;
        this.author = author;
        this.state = state;
        this.templateName = templateName;
        this.templateId = templateId;
        this.updatedAt = updatedAt;
    }
}


