import * as React from "react";
import {Dialog, DialogFooter, PrimaryButton, DefaultButton, MessageBar, MessageBarType, DialogType} from "office-ui-fabric-react";
import {SmartTemplate} from "../../model/smartTemplate";
import {SmartTemplateService} from "../../services/smartTemplateService";
import {TemplateFamilyList} from "../../components/templateFamiliyList";
import {Util, Toast} from "@voxfp/opal_ui_common";
import {OpalDropdownList} from "../opalDropdownList/opalDropdownList";

export interface Props {
    title?: string;
    onInsertSmartTemplate?: any;
    templateId: number;
    onCancel: any;
}

export interface State {
    currentSmartTemplate: SmartTemplate;
    templateOptions: Array<any>;
    errorMessage: boolean;
    formValid: boolean;
    selectedTemplateFamily: string;
}

export class InsertSmartTemplateDialog extends React.Component<Props, State> {

    smartTemplateService: SmartTemplateService = new SmartTemplateService();

    componentDidMount() {
        this.resetState();
    }

    handleInsert() {
        let isValid = this.validateForm();
        if (isValid) {
            this.smartTemplateService.insertSmartTemplate(this.state.currentSmartTemplate)
            .then((smartTemplate) => {
                this.props.onInsertSmartTemplate(smartTemplate);
                this.resetState();
            }).catch((error) => {                
                Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
            });
            return true;
        }
        else {            
            Util.showToast(new Toast('Please select an item.', MessageBarType.error));
            return false;
        }
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleTemplateFamilyChange(option) {
        let TemplateFamilyId = option.key;
        this.setState({
            selectedTemplateFamily: TemplateFamilyId
        });
        this.fetchSmartTemplates(option);
    }

    fetchSmartTemplates(TemplateFamily) {
        this.smartTemplateService.fetchSmartTemplates(TemplateFamily.key).then((data) => {  
            let templateListOptions = Util.orderOptions(data, 'id', 'label'); 
            this.setState({
                templateOptions: templateListOptions,
                errorMessage: data ? false : true
            });        
        })
        .catch((_error) => {  
            Util.showToast(new Toast("No Sub-Templates fetched from " + TemplateFamily.text, MessageBarType.error));
            this.setState({
                errorMessage: true
            });         
        });
    }

    handleSmartTemplateChange(option) {
        this.smartTemplateService.getSmartTemplate(option.key)
        .then((smartTemplate: any) => {
            this.setState({
                currentSmartTemplate: smartTemplate
            });
        })
        .catch((_error) => {                
            Util.showToast(new Toast("Error selecting Sub-Template.", MessageBarType.error));
        });
    }

    validateForm() {
        let validationCheck = this.state.currentSmartTemplate !== null && this.state.currentSmartTemplate.id > 0 ? true : false;
        this.setState({
            formValid: validationCheck
        });
        return validationCheck;
    }

    resetState() {
        this.setState({
            errorMessage: false,
            formValid: null,
        });
    }
    
    render() {
        return (
            <Dialog
                hidden={false}
                dialogContentProps={{
                    title: 'Insert Sub-Template',
                    type: DialogType.largeHeader
                }}
                modalProps={{
                    forceFocusInsideTrap: false
                }}
            >
                
                <TemplateFamilyList 
                    selectedTemplateFamily={this.state && this.state.selectedTemplateFamily} 
                    onChangeTemplateFamily={this.handleTemplateFamilyChange.bind(this)}
                />
                
                {this.state && this.state.templateOptions && 

                    <OpalDropdownList 
                        label="Sub-Template"
                        allowFiltering={true}
                        items={this.state.templateOptions}
                        onChange={(item) => { this.handleSmartTemplateChange(item); }}
                        formValid={this.state && this.state.currentSmartTemplate === null && this.state.formValid === false ? false : true} 
                        currentItem={this.state && this.state.currentSmartTemplate && this.state.currentSmartTemplate.id}
                    />
                        
                }
                {this.state && this.state.errorMessage &&
                    <MessageBar>No items to show.</MessageBar>
                }
                <DialogFooter>
                    <DefaultButton onClick={this.handleCancel.bind(this)} text="Cancel" />
                    {this.state && this.state.templateOptions &&
                        <PrimaryButton onClick={this.handleInsert.bind(this)} text="Insert" />
                    }
                </DialogFooter>
            </Dialog>
        );
    }
}
