import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartCondition} from "../model/conditionals";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartConditionService {

    private _templateId;

    constructor (templateKey: any) {
        this._templateId = templateKey;

        OpalStateManager.observeList(OpalEntity.SmartCondition, this.fetchSmartConditions);
    }

    fetchSmartConditions(playbookId) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookSmartConditions, playbookId, null, true)
            .then((data: Array<SmartCondition>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("SmartConditionService:fetchSmartConditions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartCondition(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartCondition, id)
            .then((smartCondition: SmartCondition) => {
                resolve(smartCondition);
            }).catch(error => {            
                Log.error("SmartTokenService:getSmartCondition with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    markSmartCondition(id) {
        return new Promise((resolve, reject) => {
            this.getSmartCondition(id)
            .then((condition) => {
                this.insertSmartCondition(condition)
                .then((insertedCondtion) => {
                    resolve(insertedCondtion);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    saveSmartCondition(smartCondition: SmartCondition) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.CreateSmartCondition, smartCondition, { templateId: this._templateId, Id: smartCondition.id })
            .then((smartCondition: SmartCondition) => {
                resolve(smartCondition);
            }).catch((error) => {
                Log.error("smartConditionService:saveSmartCondition", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    insertSmartCondition(smartCondition) {
        return new Promise((resolve, reject) => {
            if (typeof Word !== 'undefined') {
                Util.startSpinning();
                Word.run(async (context) => {
                    let selection = context.document.getSelection();
                    let contentControl = selection.parentContentControlOrNullObject;
                    await context.sync();
                    context.load(contentControl);
                    context.load(selection);
                    if (contentControl.isNullObject) {
                        selection.insertOoxml(smartCondition.ooxml, Word.InsertLocation.replace);
                    }
                    else {
                        reject("Error: Cannot insert insert a content control inside another content control.");
                    }
                    return context.sync().then(() => {
                        resolve(smartCondition);
                    }); 
                }).catch((error) => {
                    Log.error("smartConditionService:insertSmarCondition", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
            }
        });
    }
}
