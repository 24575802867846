import * as React from "react";
import {Label} from "office-ui-fabric-react";
import { DropDownListComponent, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import '@syncfusion/ej2-base/styles/fabric.css';
import '@syncfusion/ej2-buttons/styles/fabric.css';
import '@syncfusion/ej2-splitbuttons/styles/fabric.css';
import '@syncfusion/ej2-dropdowns/styles/fabric.css';
import '@syncfusion/ej2-inputs/styles/fabric.css';
import '@syncfusion/ej2-popups/styles/fabric.css';

export interface Props {
    label: string;
    items: Array<any>;
    allowFiltering?: boolean;
    onChange?: any;
    formValid?: boolean;
    currentItem: any;
    className?: string;
}

export class OpalDropdownList extends React.Component<Props> {

    constructor(props) {
        super(props);
    }

    handleChange(item) {
        this.props.onChange(item);
    }

    public onFiltering = (e: FilteringEventArgs) => {
        let query: Query = new Query();
        query = (e.text !== '') ? query.where('text', 'contains', e.text, true) : query;
        e.updateData(this.props.items, query);
    }

    render() {
        let itemType = this.props.label;

        let filteringProps;
        let classNameProps;

        if (this.props.allowFiltering) {
            filteringProps = {
                filtering: this.onFiltering.bind(this)
            };
        }

        if (this.props.className) {
            classNameProps = {
                className: this.props.className
            };
        }

        return(
            <div>
                <Label>{itemType}</Label>
                <div {...classNameProps}>
                    <DropDownListComponent
                        id={"select" + itemType.replace(" ", "")}
                        placeholder={"Select a " + itemType}
                        filterBarPlaceholder={'Search for a ' + itemType}
                        dataSource={this.props.items || []}
                        allowFiltering={this.props.allowFiltering}                        
                        {...filteringProps}
                        cssClass={this.props.currentItem < 1 && this.props.formValid === false ? "e-error" : ""}
                        change={
                            (item) => {
                                this.handleChange(item.itemData);
                            }
                        }
                    />
                {this.props.currentItem < 1 && this.props.formValid === false ? <div className="error-message">* required</div> : null}
                </div>
            </div>
        );
    }
}
