import {Model} from "@voxfp/opal_ui_common";

export class SmartTemplate extends Model<SmartTemplate> {

    id?: number;
    label?: string;
    templateId?: number;
    templatableId?: number;
    friendlyName?: string;
    ooxml?: string;

    constructor(id?: number, label?: string, templateId?: number, templatableId?: number, friendlyName?: string, ooxml?: string) {
        super(SmartTemplate);
        this.id = id;
        this.label = label;
        this.templateId = templateId;
        this.templatableId = templatableId;
        this.friendlyName = friendlyName;
        this.ooxml = ooxml;
    }
}
