import {Model} from "@voxfp/opal_ui_common";

export class SmartDataCategory extends Model<SmartDataCategory> {

    name: string;
    friendlyName: string;

    constructor(name?: string, friendlyName?: string) {
        super(SmartDataCategory);
        this.name = name;
        this.friendlyName = friendlyName;
    }

    toServerType(obj: SmartDataCategory) {

        let clone = JSON.parse(JSON.stringify(obj));
        clone['friendly_name'] = obj.friendlyName;
        delete clone.friendlyName;

        return clone;
    }

    fromServerType(obj): SmartDataCategory {

        obj.attributes.friendlyName = obj.attributes['friendly-name'];
        delete obj.attributes['friendly-name'];
        let newObj = obj.attributes as SmartDataCategory;
        newObj.id = obj.id;
        return newObj;
    }
}
