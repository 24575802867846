import {Model} from "@voxfp/opal_ui_common";
import { SmartDataCategory } from "./smartDataCategory";

export class SmartData extends Model<SmartData> {

    label: string;
    friendlyName: string;
    smartDataCategory: SmartDataCategory;

    constructor(label?: string, friendlyName?: string, smartDataCategory?: SmartDataCategory) {
        super(SmartData);
        this.label = label;
        this.friendlyName = friendlyName;
        this.smartDataCategory = smartDataCategory;
    }

    toServerType(obj: SmartData) {

        let clone = JSON.parse(JSON.stringify(obj));
        clone['friendly_name'] = obj.friendlyName;
        clone['smart_data_category_id'] = obj.smartDataCategory.id;
        delete clone.friendlyName;
        delete clone.smartDataCategory;

        return {"smart_datum": clone};
    }

    fromServerType(obj): SmartData {

        obj.attributes.smartDataCategory = new SmartDataCategory();
        obj.attributes.smartDataCategory.id = obj.attributes['smart-data-category-id'] || obj.attributes['category-id'];
        delete obj['smart-data-category-id'];
        obj.attributes.smartDataCategory.name = obj.attributes['category-name'];
        delete obj['category-name'];
        obj.attributes.smartDataCategory.friendlyName = obj.attributes['category-friendly-name'];
        delete obj['category-friendly-name'];
        obj.attributes.friendlyName = obj.attributes['friendly-name'];
        delete obj.attributes['friendly-name'];
        let newObj = obj.attributes as SmartData;
        newObj.id = obj.id;
        return newObj;
    }
}
