import {Model} from "@voxfp/opal_ui_common";
import {Relationships} from "./relationships";

export class DocumentContentRevision extends Model<DocumentContentRevision> {

    originalValue: string;
    value: string;
    friendlyName: string;
    status: string;
    workflowEvents: any;
    relationships: Relationships;

    constructor(existingValue?: string, revisionValue?: string, contentControlFriendlyName?: string, status?: string, workflowEvents?: any, relationships?: Relationships) {
        super(DocumentContentRevision);
        this.originalValue = existingValue;
        this.value = revisionValue;
        this.friendlyName = contentControlFriendlyName;
        this.status = status;
        this.workflowEvents = workflowEvents;
        this.relationships = relationships;
    }
}
