import * as React from 'react';
import {OpalStoreService} from "@voxfp/opal_ui_common";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';

export interface AppProps {
    title: string;
    config: object;
    history: any;
    visible: boolean;
    match: any;
    permissions?: any;
}

export interface AppState {
}

export class InitialWrap extends React.Component<AppProps, AppState>  {

    constructor(props, state) {
        super(props, state);
        Office.context.document.customXmlParts.getByNamespaceAsync("http://Opal/Metadata", (asyncResult) => {
            if (asyncResult.value.length > 0) {
                asyncResult.value[0].getNodesAsync("*", (asyncNodeResult) => {
                    asyncNodeResult.value[0].getNodeValueAsync((asyncNodeValueResult) => {
                        if (asyncNodeValueResult.status === Office.AsyncResultStatus.Failed) {
                            console.log(" getNodeValueAsync: Failed " + JSON.stringify(asyncResult));
                        }
                        else {
                            let xmlDoc;
                            let xml = asyncNodeValueResult.value;
                            if (window.DOMParser) {
                                let parser = new DOMParser();
                                xmlDoc = parser.parseFromString(xml, "text/xml");
                            }
                            else {
                                xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
                                xmlDoc.async = false;
                                xmlDoc.loadXML(xml);
                            }
                    
                            let OpalDocumentVersionIdNode = xmlDoc.getElementsByTagName("OpalDocumentVersionId")[0];
                            let OpalDocumentVersionId = OpalDocumentVersionIdNode.childNodes[0].nodeValue;

                            this.openTemplate(OpalDocumentVersionId);
                        }
                    });
                });
            }
            else {
                this.props.history.push("/home");
            }
        });
    }

    componentDidMount() {
        OpalStoreService.cleanFlashProperties();
    }

    openTemplate(id) {
        localStorage.setItem("templateType", "existing");        
        this.props.history.push("/editTemplate/" + parseInt(id, 10));
    }

    render() {

        return (
            <div className="spinnerOverlay">
                <Spinner size={SpinnerSize.large} label='Working...' ariaLive='assertive'/>
            </div>
        );
    }
}
