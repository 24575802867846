import * as React from "react";
import {Dialog, DialogFooter, PrimaryButton, DefaultButton, MessageBar, MessageBarType, ChoiceGroup, IChoiceGroupOption, DialogType} from "office-ui-fabric-react";
import {SmartRepeatService} from "../../services/smartRepeatService";
import {Log, Util, Toast} from "@voxfp/opal_ui_common";
import { SmartRepeat } from "../../model/smartRepeat";
import {OpalDropdownList} from "../opalDropdownList/opalDropdownList";

export interface Props {
    title?: string;
    onInsertSmartRepeat?: any;
    playbookId: number;
    onCancel: any;
}

export interface State {
    currentSmartRepeat: SmartRepeat;
    smartRepeatOptions: Array<any>;
    repeatType: any;
    errorMessage: boolean;
    formValid: boolean;
    tableSelected: boolean;
}

export class InsertSmartRepeatDialog extends React.Component<Props, State> {

    smartRepeatService: SmartRepeatService = new SmartRepeatService();

    componentDidMount() {
        this.resetState();
        if (typeof Office !== 'undefined' && Office.context && Office.context.document) {
            Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, this.loadSelection.bind(this));
            this.loadSelection();
        }
    }

    loadSelection() {
        if (typeof Word !== 'undefined') {
            Word.run((context) => {
                let selection = context.document.getSelection();
                let check = selection.parentTableOrNullObject; 
                return context.sync().then(() => {
                        this.setState({
                            tableSelected: !check.isNullObject
                        });
                });
            }).catch(Log.error);
        }
    }

    handleInsert() {
        let isValid = this.validateForm();        
        if (isValid) {
            this.smartRepeatService.insertSmartRepeat(this.state.currentSmartRepeat, this.state.currentSmartRepeat.smartRepeatType)
            .then((smartRepeat) => {
                this.props.onInsertSmartRepeat(smartRepeat);
                this.resetState();
            }).catch((error) => {
                this.loadSelection();                
                Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
            });
        }
        else {            
            Util.showToast(new Toast('Please select an item.', MessageBarType.error));
        }
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleSmartRepeatChange(e) {
        if(this.state.repeatType === "Chart") {
            this.smartRepeatService.getSmartChart(e.key)
            .then((smartRepeat: any) => {
                this.setState({
                    currentSmartRepeat: {...smartRepeat, smartRepeatType: "Chart"}
                });
            }).catch((error) => {
                this.setState({
                    errorMessage: true
                });  
                Util.showToast(new Toast('Error fetching Repeats. ' + error, MessageBarType.error));
            });
        }else {
            this.smartRepeatService.getSmartRepeat(e.key)
            .then((smartRepeat: any) => {
                this.setState({
                    currentSmartRepeat: smartRepeat
                });
            }).catch((error) => {
                this.setState({
                    errorMessage: true
                });  
                Util.showToast(new Toast('Error fetching Repeats. ' + error, MessageBarType.error));
            });
        }
    }

    handleChoiceChange(repeatType) {
        this.setState({
            repeatType: repeatType,
            currentSmartRepeat: null
        });
        this.smartRepeatService.fetchSmartRepeats(repeatType, this.props.playbookId).then((data) => {
            this.setState({
                smartRepeatOptions: Util.orderOptions(data, 'id', 'label')
            });
            this.setState(prevState => ({
                smartRepeatOptions: [ {key: 0, text: this.state && this.state.repeatType === "Chart" ? "Select a Chart" : "Select a Repeat", disabled: true}, ...prevState.smartRepeatOptions]
            }));
        }).catch((error) => {   
            this.setState({
                errorMessage: true
            });  
            Util.showToast(new Toast('Error fetching Repeats. ' + error, MessageBarType.error));
        });
    }

    validateForm() {
        if (this.state.currentSmartRepeat !== null && this.state.currentSmartRepeat.id > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    resetState() {
        this.setState({
            errorMessage: false,
            formValid: null
        });
    }
    
    render() {

        const options: IChoiceGroupOption[] = [
            { key: 'template', text: 'Template', iconProps: { iconName: 'FileTemplate' }},
            { key: 'table', text: 'Table', iconProps: { iconName: 'Table' } },
            { key: 'chart', text: 'Chart', iconProps: { iconName: 'Chart' }}
        ];

        return (
            <Dialog
                hidden={false}
                dialogContentProps={{
                    title: 'Insert Repeat',
                    type: DialogType.largeHeader
                }}
                modalProps={{
                    forceFocusInsideTrap: false
                }}
            >

                <ChoiceGroup 
                label="Select repeat type" 
                options={options} 
                className={"mb1 select-repeat"}
                onChange={
                    (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                        this.handleChoiceChange(option.text);
                    }
                } />

                {this.state && this.state.repeatType === "Table" && this.state.tableSelected === false &&
                    <MessageBar>Place cursor inside a table in your document to continue.</MessageBar>
                }
                
                {this.state && this.state.smartRepeatOptions && this.state.repeatType && 

                    <OpalDropdownList 
                        label={this.state && this.state.repeatType}
                        allowFiltering={true}
                        items={this.state.smartRepeatOptions}
                        onChange={(item) => { this.handleSmartRepeatChange(item); }}
                        formValid={this.state && this.state.currentSmartRepeat === null && this.state.formValid === false ? false : true} 
                        currentItem={this.state && this.state.currentSmartRepeat && this.state.currentSmartRepeat.id}
                    />
                        
                }
                
                {this.state && this.state.errorMessage &&
                    <MessageBar>No items to show.</MessageBar>
                }
                <DialogFooter>
                    <DefaultButton onClick={this.handleCancel.bind(this)} text="Cancel" />
                    {this.state && this.state.smartRepeatOptions &&
                        <PrimaryButton onClick={this.handleInsert.bind(this)} text="Insert" />
                    }
                </DialogFooter>
            </Dialog>
        );
    }
}
