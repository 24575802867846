import * as React from "react";
import {Dialog, DialogFooter, PrimaryButton, DefaultButton, MessageBar, MessageBarType, DialogType} from "office-ui-fabric-react";
import {SmartSwitch} from "../../model/switches";
import {SmartSwitchService} from "../../services/switchService";
import {Log, Util, Toast} from "@voxfp/opal_ui_common";
import {OpalDropdownList} from "../opalDropdownList/opalDropdownList";

export interface Props {
    title?: string;
    onInsertSmartSwitch?: any;
    templateKey: number;
    onCancel: any;
    playbookId?: number;
}

export interface State {
    currentSwitch: SmartSwitch;
    switchOptions: Array<any>;
    errorMessage: boolean;
    formValid: boolean;
}

export class InsertSmartSwitchDialog extends React.Component<Props, State> {

    smartSwitchService: SmartSwitchService = new SmartSwitchService();

    componentDidMount() {
        this.resetState();
        
        this.smartSwitchService.fetchSmartSwitches(this.props.playbookId).then((data) => {
            let switchListOptions: Array<any> = [];
            switchListOptions = Util.orderOptions(data, 'id', 'label');
            this.setState({
                switchOptions: switchListOptions
            });
            this.setState(prevState => ({
                switchOptions: [ {key: 0, text: "Select a Switch", disabled: true}, ...prevState.switchOptions]
            }));
        }).catch(error => {     
            Log.error("SmartSwitchService:fetchSmartSwitches", error);
            this.setState({
                errorMessage: true
            });
            Util.showToast(new Toast('Error fetching Switches.', MessageBarType.error));
        });
    }

    handleInsert() {
        let isValid = this.validateForm();
        if (isValid) {
            this.smartSwitchService.markSmartSwitch(this.state.currentSwitch.id)
            .then((smartSwitch) => {
                this.props.onInsertSmartSwitch(smartSwitch);
                this.resetState();
            }).catch((error) => {                
                Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
            });
            return true;
        }
        else {            
            Util.showToast(new Toast('Please select an item.', MessageBarType.error));
            return false;
        }
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleSwitchChange(option) {
        this.smartSwitchService.getSmartSwitch(option.key)
        .then((smartSwitch: any) => {
            this.setState({
                currentSwitch: smartSwitch
            });
        })
        .catch((error) => {                
            Util.showToast(new Toast(error.message ? error.message : error, MessageBarType.error));
        });
    }

    validateForm() {
        if (this.state.currentSwitch !== null && this.state.currentSwitch.id > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    resetState() {
        this.setState({
            errorMessage: false,
            formValid: null
        });
    }
    
    render() {
        return (
            <Dialog
                hidden={this.state && this.state.switchOptions || this.state && this.state.errorMessage ? false : true}
                dialogContentProps={{
                    title: 'Insert Switch',
                    type: DialogType.largeHeader
                }}
                modalProps={{
                    forceFocusInsideTrap: false
                }}
            >
                {this.state && this.state.switchOptions &&
                    <div>

                        <OpalDropdownList
                            label="Switch"
                            allowFiltering={true}
                            items={this.state.switchOptions}
                            onChange={(item) => { this.handleSwitchChange(item); }}
                            formValid={this.state && this.state.currentSwitch === null && this.state.formValid === false ? false : true}
                            currentItem={this.state && this.state.currentSwitch && this.state.currentSwitch.id }
                        />

                        <br/>
                        <div hidden={!this.state || !this.state.currentSwitch}>
                            <label>
                                <span>
                                    <strong>
                                        {this.state && this.state.currentSwitch && this.state.currentSwitch.label}
                                    </strong>
                                </span>
                                <br/>
                                <br/>
                                <span>{this.state && this.state.currentSwitch && this.state.currentSwitch.friendlyName}</span>
                            </label>
                        </div>
                    </div>
                }
                {this.state && this.state.errorMessage &&
                    <MessageBar>No items to show. Ensure the Playbook this template is in has Switches assigned to it.</MessageBar>
                }
                <DialogFooter>
                    <DefaultButton onClick={this.handleCancel.bind(this)} text="Cancel" />
                    {this.state && this.state.switchOptions &&
                        <PrimaryButton onClick={this.handleInsert.bind(this)} text="Insert" />
                    }
                </DialogFooter>
            </Dialog>
        );
    }
}
