import * as React from 'react';
import {PrimaryButton, IBreadcrumbItem, MessageBarType} from "office-ui-fabric-react";
import {Header} from '../../components/header';
import {TemplateAndTemplateFamily} from "../../components/templateAndTemplateFamily";
import {Util, Toast} from "@voxfp/opal_ui_common";

export interface AppProps {
    title: string;
    config: object;
    history: any;
    match: any;
    permissions?: any;
}

export interface AppState {
    selectedTemplateFamily: any;
    selectedTemplate: any;
    previewButtonDisabled: boolean;
    formValid: boolean;
}

export class CopyTemplate extends React.Component<AppProps, AppState> {
    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);
        
        this.breadcrumbList = [
            { text: 'Home', key: 'Home', onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                this.leavePage("/home");
            } },
            { text: 'Copy Template', key: 'f1', isCurrentItem: true}
        ];
        this.state = {
            selectedTemplateFamily: null,
            selectedTemplate: null,
            previewButtonDisabled: true,
            formValid: null
        };
    }

    leavePage(path) {
        this.props.history.push(path);       
    }

    handleProceed() {
        let isValid = this.validateForm();
        if (isValid) {
            this.navigateToNewTemplate();
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    navigateToNewTemplate() {
        this.props.history.push('/newTemplate/' + this.state.selectedTemplate);
    }

    handleSelectTemplateFamily(option) {
        this.setState({selectedTemplateFamily: option});
    }

    handleSelectTemplate(option) {
        this.setState({selectedTemplate: option.key, previewButtonDisabled: false});
    }

    validateForm() {
        let validationCheck = this.state.selectedTemplateFamily > 0 && this.state.selectedTemplate > 0 ? true : false;
        this.setState({
            formValid: validationCheck
        });
        return validationCheck;       
    }

    render() {

        return (
            <div className='ms-navigation__main'>
                <Header match={this.props.match} message='' history={this.props.history} breadcrumbLinks={this.breadcrumbList} permissions={this.props.permissions} />
                <section className='ms-navigation__content p1 ms-font-m ms-fontColor-neutralPrimary'>
                    <TemplateAndTemplateFamily 
                        onChangeTemplateFamily={this.handleSelectTemplateFamily.bind(this)}
                        onChangeTemplate={this.handleSelectTemplate.bind(this)}
                        selectedTemplateFamily={this.state && this.state.selectedTemplateFamily}
                        selectedTemplate={this.state && this.state.selectedTemplate}  
                        formValid={this.state && this.state.formValid}
                    />
                    <br/>
                    <PrimaryButton text={'Copy Template'} disabled={this.state && this.state.selectedTemplate < 1} onClick={this.handleProceed.bind(this)} />
                </section>
               
            </div>
        );
    }

}
