import {Model} from "@voxfp/opal_ui_common";

export class ReviewState extends Model<ReviewState> {

    name: string;
    sequence: number;

    constructor(name?: string, sequence?: number) {
        super(ReviewState);
        this.name = name;
        this.sequence = sequence;
    }
}
