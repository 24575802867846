import {Model} from "@voxfp/opal_ui_common";
import {SwitchValue} from "./SwitchValue";
import {ContentControl} from "./ContentControl";

export class SmartSwitch extends Model<SmartSwitch> {

    playbookId: number;    
    name: string;
    description: string;
    switch: Array<SwitchValue>;
    defaultContentControlId: number;
    caseContentControlId?: number;
    label?: string;
    friendlyName?: string;
    id?: number;
    contentControl?: ContentControl;
    ooxml?: any;

    constructor(playbookId?: number, name?: string, description?: string, switchValue?: Array<SwitchValue>, defaultContentControlId?: number, caseContentControlId?: number, label?: string, friendlyName?: string, id?: number, contentControl?: ContentControl, ooxml?: any) {
        super(SmartSwitch);
        this.id = id;
        this.playbookId = playbookId;
        this.label = label;
        this.friendlyName = friendlyName;
        this.name = name;
        this.description = description;
        this.switch = switchValue;
        this.contentControl = contentControl;
        this.defaultContentControlId = defaultContentControlId;
        this.caseContentControlId = caseContentControlId;
        this.ooxml = ooxml;
    }
}
