import * as React from 'react';
import {ActionButton} from 'office-ui-fabric-react';
import {HamburgerButton} from 'react-hamburger-button'; 
import {getTheme} from '@uifabric/styling';
import {Menu} from '../components/menu';


export interface MenuProps {
  history: any;
  match?: any;
  unsavedChanges?: boolean;
  permissions?: any;
}

export interface MenuState {
    sideMenuVisible: boolean;
}

export class SideMenu extends React.Component<MenuProps, MenuState> {
    private theme = getTheme();

    constructor(props, context) {
        super(props, context);
        this.state = {
            sideMenuVisible: false
        };
    }

    render() {
        
        return <div>  
            {this.props && this.props.match.path !== "/" &&          
                <ActionButton 
                    title="Menu" 
                    ariaLabel="Menu" 
                    className={"hamburger-button ms-bgColor-neutralQuaternaryAlt--hover m1"} 
                    onClick={() => this.setState({ sideMenuVisible: !this.state.sideMenuVisible })}>
                    <HamburgerButton
                        open={this.state.sideMenuVisible}
                        onClick={() => this.setState({ sideMenuVisible: !this.state.sideMenuVisible })}         
                        width={20}
                        height={10}
                        color={this.theme.palette.themePrimary}
                        strokeWidth={1}
                        animationDuration={0.2}
                    />
                    <span className="ml05">
                        {this.state.sideMenuVisible ? "Close" : "Menu"}
                    </span>
                </ActionButton>   
            }                 
            
            <div className={this.state.sideMenuVisible ? "ms-bgColor-neutralLighterAlt side-menu p1 open" : "ms-bgColor-neutralLighterAlt side-menu p1"}>
                <Menu history={this.props.history} unsavedChanges={this.props.unsavedChanges || false} permissions={this.props.permissions} />
            </div>
            
        </div>;
    }
}
