import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartRepeat} from "../model/smartRepeat";
import {SmartChart} from "../model/smartChart";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartRepeatService {

    constructor () {
        OpalStateManager.observeList(OpalEntity.SmartRepeat, this.fetchSmartRepeats);
    }

    fetchSmartRepeats(repeatType, playbookId?) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            let id = playbookId ? playbookId : null;
            if (repeatType === 'Chart') {
                return OpalStateManager.fetchEntityList(OpalEntity.PlaybookChart, id, null, true)
                .then((data: any) => {
                    let items = [];
                    data.forEach(item => {
                        if (item.type === 'smart-charts') {
                            items.push(item);
                        }
                    }); 
                    resolve(data);
                }).catch(error => {      
                    Log.error("SmartChartService:fetchSmartCharts", error);
                    reject(CommonUtils.FormatError(error)); 
                }).finally(() => {
                    Util.stopSpinning();
                });
            }
            else {
                return OpalStateManager.fetchEntityList(OpalEntity.PlaybookSmartRepeat, id, null, true)
                    .then((data: any) => {
                        let items = [];
                        data.forEach(item => {
                            if (item.smartRepeatType === repeatType) {
                                items.push(item);
                            }
                        });                           
                        resolve(items);
                    }).catch(error => {
                        Log.error("SmartRepeatService:getSmartRepeat", error);
                        reject(CommonUtils.FormatError(error));
                    }).finally(() => {            
                        Util.stopSpinning();
                });
            }
        });
    }

    getSmartRepeat(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartRepeat, id)
            .then(async (smartRepeat: SmartRepeat) => {
                resolve(smartRepeat);
            }).catch(error => {
                Log.error("SmartRepeatService:getSmartRepeat", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {            
                Util.stopSpinning();
            });
        });
    }

    getSmartChart(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartChart, id)
            .then(async (chart: SmartChart) => {
                resolve(chart);
            }).catch(error => {            
                Log.error("SmartChartService:getSmartChart", error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    insertSmartRepeat(repeat, repeatType) { 
        if (repeatType === "Template") {
            return new Promise((resolve, reject) => {
                Util.startSpinning();
                this.insertSmartRepeatTemplate(repeat)
                .then((repeat) => {
                    resolve(repeat);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    Util.stopSpinning();
                });
            });
        }
        else if (repeatType === "Chart") {
            return new Promise((resolve, reject) => {
                Util.startSpinning();
                this.insertChart(repeat)
                .then((repeat) => {
                    resolve(repeat);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    Util.stopSpinning();
                });
            });
        }
        else {
            return new Promise((resolve, reject) => { 
                Util.startSpinning();
                this.getSmartRepeatTable(repeat)
                .then((repeatTable) => { 
                    this.insertSmartRepeatTable(repeatTable)
                    .then((ooxml) => {
                        resolve(ooxml);
                    })
                    .catch((error) => {
                        reject(error);
                    }); 
                })
                .catch((error) => {                   
                    reject(error);
                }).finally(() => {
                    Util.stopSpinning();
                }); 
            }); 
        }
    }

    insertSmartRepeatTemplate(repeat) {
        return new Promise((resolve, reject) => { 
            Word.run(async (context) => {
                let selection = context.document.getSelection();
                let contentControl = selection.parentContentControlOrNullObject;
                await context.sync();
                context.load(contentControl);
                context.load(selection);
                if (contentControl.isNullObject) {
                    selection.insertOoxml(repeat.ooxml, Word.InsertLocation.replace);
                }
                else {
                    reject("Error: Cannot insert insert a content control inside another content control.");
                }
                return context.sync().then(() => {
                    resolve(repeat);
                }); 
            }).catch((error) => {
                Log.error("smartRepeatService:insertSmartRepeatTemplate", error);
                reject(CommonUtils.FormatError(error));                
            });
        });
    }

    insertChart(chart) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            Word.run(async (context) => {
                let selection = context.document.getSelection();
                let contentControl = selection.parentContentControlOrNullObject;
                await context.sync();
                context.load(contentControl);
                context.load(selection);
                let ooxml = chart.ooxml.replace(/(?:\r\n|\r|\n)/g, '');
                if (contentControl.isNullObject) {
                    selection.insertOoxml(ooxml, Word.InsertLocation.replace);
                }
                else {
                    reject("Error: Cannot insert a content control inside another content control.");
                }
                return context.sync().then(() => {
                    resolve(chart);
                });              
            }).catch((error) => {
                Log.error("SmartChartService:insertChart", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    insertSmartRepeatTable(repeatTable) {
        return new Promise((resolve, reject) => {               
            Word.run(async (context) => {
                let selection = context.document.getSelection();
                let table = selection.parentTable;
                let contentControl = selection.parentContentControlOrNullObject;
                await context.sync();
                context.load(contentControl);
                context.load(selection);
                if (contentControl.isNullObject) {
                    table.delete();
                    let newSelection = context.document.getSelection();
                    await context.sync();   
                    let ooxml = repeatTable.tableOoxml;
                    ooxml = ooxml.replace(/(?:\r\n|\r|\n)/g, '');
                    newSelection.insertOoxml(ooxml, Word.InsertLocation.after);
                    await context.sync();
                    return context.sync().then(() => {
                        resolve(ooxml);
                    }); 
                }
                else {
                    reject("Error: Cannot insert insert a content control inside another content control.");
                }                
            }).catch((error) => {
                Log.error("smartRepeatService:insertSmartRepeatTable", error);
                reject(CommonUtils.FormatError(error));  
            });
        });
    }

    getSmartRepeatTable(repeat) {
        return new Promise((resolve, reject) => {
            Word.run(async (context) => {
                let selection = context.document.getSelection(); 
                let table = selection.parentTableOrNullObject;
                await context.sync();                  
                if (!table.isNullObject) {
                    let tableOoxml = table.getRange().getOoxml();
                    await context.sync();                
                    OpalStateManager.createEntity(OpalEntity.SmartRepeatTableRow, {table_ooxml: tableOoxml.value}, repeat.id)
                    .then((repeatTable: SmartRepeat) => {
                        resolve(repeatTable);
                    }).catch(error => {   
                        Log.error("smartRepeatService:getSmartRepeatTable", error);
                        reject(CommonUtils.FormatError(error)); 
                    });
                }
                else {                    
                    reject("No table selected in document.");
                }
            }).catch(error => { 
                Log.error("smartRepeatService:getSmartRepeatTable", error);  
                reject(CommonUtils.FormatError(error));  
            });
        });
    }

}
