import {Model} from "@voxfp/opal_ui_common";
import {TemplateFamily} from "./templateFamily";

export class Template extends Model<Template> {

    name: string;
    shortName: string;
    version?: string;
    ooxml?: string;
    templateFamilyId?: number;
    templateFamily: TemplateFamily;
    currentRevision?: any;
    templateSubTemplate: boolean;

    constructor(name?: string, shortName?: string, version?: string, ooxml?: string, templateFamilyId?: number, templateFamily?: TemplateFamily, currentRevision?: any, templateSubTemplate?: boolean) {
        super(Template);
        this.name = name;
        this.shortName = shortName;
        this.version = version;
        this.ooxml = ooxml;
        this.templateFamilyId = templateFamilyId;
        this.templateFamily = templateFamily;
        this.currentRevision = currentRevision;
        this.templateSubTemplate = templateSubTemplate;
    }
}
