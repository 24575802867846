import {Model} from "@voxfp/opal_ui_common";

export class SmartRule extends Model<SmartRule> {
    id: number;
    name: string;
    description: string;
    tokenTypeName: string;

    constructor(id?: number, name?: string, description?: string, tokenTypeName?: string) {
        super(SmartRule);
        this.id = id;
        this.name = name;
        this.description = description;
        this.tokenTypeName = tokenTypeName;
    }
}
