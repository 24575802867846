export class ServiceObserver {

    static handlers: Object = {};

    static addHandler(event: string, handler: Function) {
        if (!this.handlers[event]) {
            this.handlers[event] = new Array<Function>();
        }
        this.handlers[event].push(handler);
    }

    static removeEventHandlers(event: string) {
        this.handlers[event] = null;
    }

    static removeServiceHandlers(service: string) {
        Object.keys(this.handlers).forEach((key) => {
            if ( key.indexOf(service) !== -1) {
                this.handlers[key] = null;
            }
        });
    }

    static trigger(event: string, data: any) {
        if (this.handlers[event]) {
            this.handlers[event].forEach((handler) => {
                handler(data);
            });
        }
    }
}
