import * as React from 'react';
import {Header} from '../../components/header';
import {
    PrimaryButton,
    TextField,
    DefaultButton,
    IBreadcrumbItem,
    MessageBarType,
    Checkbox
} from 'office-ui-fabric-react';
import {TemplateFamilyList} from "../../components/templateFamiliyList";
import {OpalDialog} from "../../components/dialog";
import {Log, Util, Toast} from "@voxfp/opal_ui_common";
import {TemplateService} from "../../services/templateService";
import {Template} from "../../model/template";
import {CommonUtils} from '../../utils/commonUtils';

export interface AppProps {
    title: string;
    config: object;
    history: any;
    match: any;
    permissions?: any;
}

export interface AppState {
    selectedTemplateFamily: any;
    templateName: string;
    templateShortName: string;
    templateSubTemplate: boolean;
    templateDescription: string;
    displayError: boolean;
    errorMessage: string;
    displayCleanContentConfirmation: boolean;
    templateToCopyKey: string;
    formValid: boolean;
    showForm: boolean;
}

export class NewTemplate extends React.Component<AppProps, AppState> {

    templateService: TemplateService = new TemplateService();
    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);
        
        this.breadcrumbList = [
            { text: 'Home', key: 'Home', onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                this.leavePage("/home");
            } },
            { 
                text: this.props.match.params.templateToCopyKey ? 'Copy Template' : 'New Template', 
                key: 'f1', 
                isCurrentItem: true }
        ];
        this.state = {
            selectedTemplateFamily: null,
            templateName: '',
            templateShortName: '',
            templateSubTemplate: true,
            templateDescription: '',
            displayError: false,
            errorMessage: null,
            displayCleanContentConfirmation: false,
            templateToCopyKey: null,
            formValid: null,
            showForm: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.templateToCopyKey) {
            this.populateWithCopyTemplateData();
        }
        else {
            this.setState({
                showForm: true
            });
        }
    }

    leavePage(path) {
        this.props.history.push(path);       
    }

    populateWithCopyTemplateData() {
        this.templateService.getTemplate(this.props.match.params.templateToCopyKey).then((template: Template) => {
            this.setState({
                selectedTemplateFamily: template.templateFamily['id'].toString(),
                templateName: template.name + ' - copy',
                templateShortName: template.shortName,
                templateSubTemplate: template.templateSubTemplate,
                showForm: true
            });
        })
        .catch((error) => {
            Util.showToast(new Toast(error, MessageBarType.error));
        });
    }

    saveTemplate = () => {
        let isValid = this.validateForm();
        if (isValid) {
            let template = {
                name: this.state.templateName,
                short_name: this.state.templateShortName,
                sub_template: this.state.templateSubTemplate,
                template_family_id: this.state.selectedTemplateFamily,
                description: this.state.templateDescription
            };

            let save = null;
            if (this.props.match.params.templateToCopyKey) {
                save = this.templateService.copyTemplate(template, this.props.match.params.templateToCopyKey);
            } else {
                save = this.templateService.saveTemplate(template);
            }

            save.then((result) => {
                localStorage.setItem("templateType", this.props.match.params.templateToCopyKey ? "copy" : "new");
                if (this.props.match.params.templateToCopyKey) {
                    this.displayCopiedTemplate(result.id);
                }
                else {
                    this.navigateToEditTemplate(result.id);
                }
            }).catch((error) => {   
                Util.showToast(new Toast(error, MessageBarType.error));
                this.setState({errorMessage: CommonUtils.FormatError(error), displayError: true});  
            }).catch(Log.error);
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    navigateToEditTemplate(id) {
        this.props.history.push('/editTemplate/' + id);
    }

    displayCleanContentDialog() {
        this.setState({displayCleanContentConfirmation: true});
    }

    cleanContentDialogYesHandler() {
        if (typeof Word !== 'undefined') {
            Word.run(async (context) => {
                context.document.body.clear();
                await context.sync();
            });
        }
        this.setState({ displayCleanContentConfirmation: false });
    }

    cleanContentDialogNoHandler() {
        this.setState({ displayCleanContentConfirmation: false });
    }

    handleTemplateFamilyChange(option) {
        let TemplateFamilyId = option.key;
        this.setState({
            selectedTemplateFamily: TemplateFamilyId
        });
    }

    validateForm() {
        let validationCheck = this.state.selectedTemplateFamily > 0 && this.state.templateName.length > 0 && this.state.templateShortName.length > 0 ? true : false;
        this.setState({
            formValid: validationCheck
        });
        return validationCheck;       
    }

    displayCopiedTemplate(id) { 
        let templateId = id;        
        Util.startSpinning();
        this.templateService.getTemplate(templateId).then((template: Template) => {

            if (typeof Word !== 'undefined') {
                let ooxml = template.ooxml;
                Word.run(async (context) => {

                    context.document.properties.load('title');                    

                    let sections = context.document.sections;
                    context.load(sections);

                    await context.sync();

                    sections.items.forEach((section) => {
                        if (ooxml['body']) {
                            section.body.insertOoxml(ooxml['body'], "Replace");
                        }
                        else {
                            section.body.clear();
                        }
                        if (ooxml['primary-header']) {
                            section.getHeader('Primary').insertOoxml(ooxml['primary-header'], "Replace");
                        }
                        else {
                            section.getHeader('Primary').clear();
                        }
                        if (ooxml['primary-footer']) {
                            section.getFooter('Primary').insertOoxml(ooxml['primary-footer'], "Replace");
                        }
                        else {
                            section.getFooter('Primary').clear();
                        }                                              
                        if (ooxml['first-header']) {
                            section.getHeader('FirstPage').insertOoxml(ooxml['first-header'], "Replace");
                        }
                        else {
                            section.getHeader('FirstPage').clear();
                        }
                        if (ooxml['first-footer']) {
                            section.getFooter('FirstPage').insertOoxml(ooxml['first-footer'], "Replace");
                        }
                        else {
                            section.getFooter('FirstPage').clear();
                        }
                        if (ooxml['even-header']) {
                            section.getHeader('EvenPages').insertOoxml(ooxml['even-header'], "Replace");
                        }
                        else {
                            section.getHeader('EvenPages').clear();
                        }
                        if (ooxml['even-footer']) {
                            section.getFooter('EvenPages').insertOoxml(ooxml['even-footer'], "Replace");
                        }
                        else {
                            section.getFooter('EvenPages').clear();
                        }
                        
                    });

                    context.document.properties.set({title: template.name});
                    let contentControls = context.document.body.contentControls;                    
                    context.load(contentControls);

                    await context.sync();

                    contentControls.items.forEach((contentControl) => {
                        contentControl.appearance = 'Hidden';
                    });

                    await context.sync();
                            
                })
                .catch((error) => {
                    console.log(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
            } else {
                Util.stopSpinning();
            }

            this.navigateToEditTemplate(id);

        }).catch((error) => {
            Util.showToast(new Toast(error, MessageBarType.error));
        });
    }

    render() {
        
        return (
            <div className='ms-navigation__main'>
                <Header match={this.props.match} message='' history={this.props.history} breadcrumbLinks={this.breadcrumbList} permissions={this.props.permissions} />
                <section className='ms-navigation__content p1 ms-font-m ms-fontColor-neutralPrimary'>
                    {!this.props.match.params.templateToCopyKey &&
                        <DefaultButton
                            text='Reset Document Content'
                            iconProps={{ iconName: "ClearFormatting" }}
                            className='mb1'
                            style={{ width: "100% "}}
                            onClick={this.displayCleanContentDialog.bind(this)}
                        />
                    }
                    <TemplateFamilyList 
                        onChangeTemplateFamily={this.handleTemplateFamilyChange.bind(this)}
                        selectedTemplateFamily={this.state && this.state.selectedTemplateFamily} 
                        formValid={this.state && this.state.selectedTemplateFamily < 1 && this.state.formValid === false ? false : true}
                    />
                    <TextField
                        label='Template Name '
                        placeholder='Insert template name'
                        value = {this.state.templateName}
                        className='mb1'
                        errorMessage={this.state && this.state.templateName.length === 0 && this.state.formValid === false ? "* required" : null} 
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                            this.setState({templateName: value});
                        }}
                    />
                    <TextField
                        label='Template Short Name '
                        placeholder='Insert template short name'
                        value = {this.state.templateShortName}
                        className='mb1'
                        errorMessage={this.state && this.state.templateShortName.length === 0 && this.state.formValid === false ? "* required" : null} 
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                            this.setState({templateShortName: value});
                        }}
                    />
                    <Checkbox
                        label='Sub-Template'
                        checked = {this.state.templateSubTemplate}
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: boolean) => {
                            this.setState({templateSubTemplate: value});
                        }}
                    />
                    <br/>
                    <PrimaryButton text='Continue' onClick={this.saveTemplate.bind(this)} />
                    <OpalDialog
                        text={this.state.errorMessage || 'Sorry - something went wrong.'}
                        title={'Error'}
                        visible={this.state.displayError}
                        always={ () => this.setState({displayError: false}) }
                    />
                    <OpalDialog
                        text={'This will empty the current document before creating your new template. Are you sure?'}
                        title={'Clear content?'}
                        visible={this.state.displayCleanContentConfirmation}
                        okLabel={'Yes'}
                        cancelLabel={'No'}
                        canCancel={true}
                        onOK={this.cleanContentDialogYesHandler.bind(this)}
                        onCancel={this.cleanContentDialogNoHandler.bind(this)}
                        always={ () => this.setState({displayCleanContentConfirmation: false}) }
                    />
                </section>
               
            </div>
        );
    }

}
