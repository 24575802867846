import * as React from 'react';
import { render } from 'react-dom';
import { App } from './components/app';
import './assets/styles/global.scss';
import './polyfills';
import { HashRouter as Router } from 'react-router-dom';
import { Config } from "@voxfp/opal_ui_common";

export interface AppProps {
    title: string;
    config: object;
    match: any;
    history: any;
    location: any;
}

(() => {
    const title = 'Opal Add-in';
    const container = document.querySelector('#container');
    let loaded = false;

    const doRender = () => {
        loaded = true;
        render(
            <Router>
                <App title={title}/>
            </Router>,
            container
        );
    };

    if (Config.getEnv() === 'development') {
        setTimeout(() => {
            if (!loaded) {
                doRender();
            }
        }, 1000);
    }
    Office.initialize = () => {
        doRender();
    };
})();

