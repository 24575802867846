import {Model} from "@voxfp/opal_ui_common";

export class SmartRepeat extends Model<SmartRepeat> {

    id?: number;
    label?: string;
    templateId?: number;
    friendlyName?: string;
    ooxml?: any;
    playbookId?: number;
    playbook?: string;
    smartRepeatType?: string;
    smartRepeatTypeId?: number;
    tableRow?: any;
    tableOoxml?: any;
    value?: string;

    constructor(
        id?: number,
        label?: string,
        templateId?: number,
        friendlyName?: string,
        ooxml?: any,
        playbookId?: number,
        playbook?: string,
        smartRepeatType?: string,
        smartRepeatTypeId?: number,
        tableRow?: any,
        tableOoxml?: any,
        value?: string

    ) {
        super(SmartRepeat);
        this.id = id;
        this.label = label;
        this.templateId = templateId;
        this.friendlyName = friendlyName;
        this.ooxml = ooxml;
        this.playbookId = playbookId;
        this.playbook = playbook;
        this.smartRepeatType = smartRepeatType;
        this.smartRepeatTypeId = smartRepeatTypeId;
        this.tableRow = tableRow;
        this.tableOoxml = tableOoxml;
        this.value = value;
    }
}
