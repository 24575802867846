import {SmartToken} from "../model/smartToken";
import {SmartChart} from "../model/smartChart";
import {SmartTag} from "../model/smartTag";
import {SmartRule} from "../model/smartRule";
import {SmartData} from "../model/smartData";
import {SmartDataValue} from "../model/smartDataValue";
import {SmartDataCategory} from "../model/smartDataCategory";
import {EntityApi, HttpApi} from "@voxfp/opal_ui_common";
import {Template} from "../model/template";
import {SmartTemplate} from "../model/smartTemplate";
import {TemplateFamily} from "../model/templateFamily";
import {SmartCondition} from "../model/conditionals";
import {SmartSwitch} from "../model/switches";
import {SmartRepeat} from "../model/smartRepeat";
import {DocumentMetadata} from "../model/documentMetadata";
import {DocumentContentRevision} from "../model/documentContentRevision";
import {ReviewState} from "../model/reviewState";
import {DocumentRevision} from "../model/documentRevision";
import {Document} from "../model/document";
import {WorkflowEvent} from "../model/workflowEvent";

export class ApiArguments {
    Id?: number;
    templateId?: number;
    templatableId?: number;
    templateFamilyId?: number;
    scope?: string;
    type?: string;
    tokenId?: number;
    chartId?: number;
    smartRuleId?: number;
    documentId?: number;
}

export class TemplateApi extends EntityApi<Template> {
    constructor() {
        super({url: '/templates', isJSON: true, dataIsQueryParams: false, modelClass: new Template()});
    }
}

export class TemplateCopyApi extends EntityApi<Template> {
    constructor(args: ApiArguments) {
        super({url: '/templates?source_template_id=' + args.templateId, isJSON: true, dataIsQueryParams: false, modelClass: new Template()});
    }
}

export class TemplateTokenApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({
            url: '/templates/' + args.templateId + '/smart_tokens' + (args.scope ? '?scope=' + args.scope : ''),
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartToken()
        });
    }
}

export class PlaybookTokenApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/playbooks/' + args + '/smart_tokens',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartToken()
        });
    }
}

export class PlaybookChartApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/playbooks/' + args + '/smart_charts',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartChart()
        });
    }
}

export class TemplateTagApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        let a = {
            url: '/templates/' + args.templateId + '/smart_tags' + (args.scope ? '?scope=' + args.scope : ''),
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartTag()
        };
        super(a);
    }
}

export class TemplateContentControlApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/templates/' + args.templateId + '/content_controls?scope=' + args.scope + '&type=' + args.type, isJSON: true, dataIsQueryParams: false});
    }
}

export class TemplateSmartRuleApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({
            url: '/templates/' + args.templateId + '/smart_rules?scope=playbook',
            isJSON: false,
            dataIsQueryParams: true,
            modelClass: new SmartRule()
        });
    }
}

export class SmartRuleApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_rules',
            isJSON: true,
            dataIsQueryParams: true,
            modelClass: new SmartRule()
        });
    }
}

export class TemplateSmartDataApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({
            url: '/templates/' + args.templateId + '/smart_data',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartData()
        });
    }
}

export class SmartDataApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_data',
            isJSON: true,
            dataIsQueryParams: true,
            modelClass: new SmartData()
        });
    }
}

export class SmartDataCategoryApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_data_categories',
            isJSON: true,
            dataIsQueryParams: true,
            modelClass: new SmartDataCategory()
        });
    }
}

export class SmartDataValueApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_data_values',
            isJSON: true,
            dataIsQueryParams: true,
            modelClass: new SmartDataValue()
        });
    }
}

export class TokenSmartRuleApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({
            url: '/smart_tokens/' + args.tokenId + '/smart_rules',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartRule()
        });
    }
}

export class SmartRuleParameterApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/smart_rules/' + args.smartRuleId + '/smart_rule_parameters', isJSON: false, dataIsQueryParams: true});
    }
}

export class SmartTemplateApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_templates/',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartTemplate()
        });
    }
}

export class TemplateFamilySmartTemplatesApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({
            url: '/template_families/' + args + '/smart_templates',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartTemplate()
        });
    }
}

export class PlaybookSmartConditionsApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/playbooks/' + args + '/smart_conditions',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartCondition()
        });
    }
}


export class SmartConditionApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_conditions',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartCondition()
        });
    }
}

export class PlaybookSmartSwitchesApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/playbooks/' + args + '/smart_switches',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartSwitch()
        });
    }
}

export class SmartSwitchApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_switches',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartSwitch()
        });
    }
}

export class CreateSmartSwitchApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({
            url: '/templates/' + args.templateId + '/template_content_controls?templateable_type=SmartSwitch&templateable_id=' + args.Id, 
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new SmartSwitch()
        });
    }
}

export class TokenApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_tokens',
            isJSON: false,
            dataIsQueryParams: false,
            modelClass: new SmartToken()
        });
    }
}

export class SmartChartApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_charts',
            isJSON: false,
            dataIsQueryParams: false,
            modelClass: new SmartChart()
        });
    }
}

export class TagApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/smart_tags',
            isJSON: false,
            dataIsQueryParams: true,
            modelClass: new SmartTag()
        });
    }
}

export class DocumentTagValueApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/documents/' + args.documentId + '/content_controls', isJSON: true, dataIsQueryParams: false});
    }
}

export class TokenTypeApi extends EntityApi<any> {
    constructor() {
        super({url: '/token_types', isJSON: false, dataIsQueryParams: true});
    }
}

export class ChartTypeApi extends EntityApi<any> {
    constructor() {
        super({url: '/chart_types', isJSON: false, dataIsQueryParams: true});
    }
}

export class TemplateFamilyApi extends EntityApi<TemplateFamily> {
    constructor() {
        super({url: '/template_families?group_view=true', isJSON: true, dataIsQueryParams: false, modelClass: new TemplateFamily()});
    }
}

export class TemplateFamilyTemplatesApi extends EntityApi<Template> {
    constructor(args) {
        super({url: '/template_families/' + args + '/templates', isJSON: true, dataIsQueryParams: false, modelClass: new Template()});
    }
}

export class DocumentApi extends EntityApi<any> {
    constructor() {
        super({url: '/documents', isJSON: true, dataIsQueryParams: false});
    }
}

export class DocumentNegotiationsApi extends EntityApi<Document> {
    constructor() {
        super({url: '/documents?workflow_state=changes_to_review', isJSON: true, dataIsQueryParams: false, modelClass: new Document()});
    }
}

export class DocumentTokenValueApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/documents/' + args.documentId + '/document_contents?ooxml=y', isJSON: true, dataIsQueryParams: false});
    }
}

export class DocumentChartValueApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/documents/' + args.documentId + '/document_contents?ooxml=y', isJSON: true, dataIsQueryParams: false});
    }
}

export class DocumentMetadataApi extends EntityApi<DocumentMetadata> {
    constructor() {
        super({url: '/metadata/extract', isJSON: true, dataIsQueryParams: false, modelClass: new DocumentMetadata()});
    }
}

export class DocumentContentRevisionApi extends EntityApi<DocumentContentRevision> {
    constructor(args: ApiArguments) {
        super({
            url: '/document_revisions/' + args.documentId + '/document_content_revisions',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentContentRevision()
        });
    }
}

export class DocumentRevisionApi extends EntityApi<DocumentRevision> {
    constructor() {
        super({url: '/document_revisions',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentRevision()
        });
    }
}

export class CurrentDocumentRevisionApi extends EntityApi<DocumentRevision> {
    constructor(args: ApiArguments) {
        super({url: '/documents/' + args.documentId + '/document_revisions/current_revision',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentRevision()
        });
    }
}


export class ReviewStateApi extends EntityApi<ReviewState> {
    constructor() {
        super({
            url: '/workflow_states?workflow_type_name=Document Content Revision',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new ReviewState()
        });
    }
}

export class WorkflowEventApi extends EntityApi<WorkflowEvent> {

    constructor(documentId?, workflowEventId?) {
        super({
            url: '/document_content_revisions/' + documentId + '/workflow_events?workflow_event_id=' + workflowEventId
        });
    }
  }

export class SmartRepeatApi extends EntityApi<SmartRepeat> {
    constructor() {
        super({
            url: '/smart_repeats',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartRepeat()});
    }
}

export class SmartRepeatTableRowApi extends EntityApi<SmartRepeat> {
    constructor(args) {
        super({
            url: '/smart_repeats/' + args + '/table',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartRepeat()});
    }
}

export class PlaybookSmartRepeatApi extends EntityApi<SmartRepeat> {
    constructor(args) {
        super({
            url: '/playbooks/' + args + '/smart_repeats',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartRepeat()
        });
    }
}

export class LoginApi {
    public static login = new HttpApi().login;
    public static checkLogin = new HttpApi().checkLogin;
}

export class PermissionsApi {
    public static getPermissions = new HttpApi().getPermissions;
}
