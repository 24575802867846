import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartTemplate} from "../model/smartTemplate";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartTemplateService {

    constructor () {
        OpalStateManager.observeList(OpalEntity.SmartTemplate, this.fetchSmartTemplates);
    }

    fetchSmartTemplates(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateFamilySmartTemplates, id, null, true)
            .then((smartTemplates: any) => {
                resolve(smartTemplates);
            }).catch((error) => {
                Log.error("SmartTemplateService:fetchSmartTemplates - Error fetching templates from Template Family ID:" + id, error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartTemplate(id) {
        return new Promise((resolve, reject) => { 
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartTemplate, id, null, true)
            .then(async (template: SmartTemplate) => {
                resolve(template);
            }).catch((error) => {
                Log.error("SmartTemplateService:getSmartTemplate with ID=" + id, error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    insertSmartTemplate(smartTemplate) {
        Util.startSpinning();
        return new Promise((resolve, reject) => { 
            if (typeof Word !== 'undefined') {
                Word.run(async (context) => {
                    let selection = context.document.getSelection();
                    let contentControl = selection.parentContentControlOrNullObject;
                    await context.sync();
                    context.load(contentControl);
                    context.load(selection);
                    if (contentControl.isNullObject) {
                        selection.insertOoxml(smartTemplate.ooxml, Word.InsertLocation.replace);
                    }
                    else {
                        reject("Error: Cannot insert insert a content control inside another content control.");
                    }
                    return context.sync().then(() => {
                        resolve(smartTemplate);
                    }); 
                }).catch((error) => {
                    Log.error("smartTemplateService:insertSmartTemplate", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
            }
        });
    }

}
