import * as React from 'react';
import {HashRouter, Route, Switch, Redirect, withRouter} from 'react-router-dom';
import {Login} from '../pages/login';
import {NewTemplate} from '../pages/template/newTemplate';
import {EditTemplate} from '../pages/template/editTemplate';
import {InitialWrap} from "../pages/initialWrap";
import {Home} from "../pages/home";
import {SelectTemplate} from "../pages/template/selectTemplate";
import {CopyTemplate} from "../pages/template/copyTemplate";
import {LoginApi as HttpLogin} from '../services/http';
import {initializeIcons} from '@uifabric/icons';
import {buildAbilities, AbilityContext, OpalStoreService} from '@voxfp/opal_ui_common';

initializeIcons();

export interface AppProps {
    title: string;
    config?: object;
    match?: any;
    history?: any;
    location?: any;
}

export interface AppState {}

if (window.ENV.NODE_ENV !== 'production') {
    localStorage.setItem('debug', 'opal-add-in:*');
}

@withRouter
export class App extends React.Component<AppProps, AppState> {

    private ability;

    constructor(props, state) {
        super(props, state);
    }

    componentDidMount() {
        //Enable auto-open
        if (Office && Office.context && Office.context.document) {
            Office.context.document.settings.set("Office.AutoShowTaskpaneWithDocument", true);
            Office.context.document.settings.saveAsync();
        }
    }

    onRouteChanged = () => {
        HttpLogin.checkLogin().then().catch(() => window.location.href = '/#/login');
        return true;
    }

    render() {

        const rules = OpalStoreService.getProperty("permissions");
        this.ability = buildAbilities(rules);

        return (
            <AbilityContext.Provider value={this.ability}>

                <HashRouter>
                    <Switch>
                        <Route exact path='/' render={props => this.onRouteChanged() &&  <InitialWrap visible={true} config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Route exact path='/home' render={props => this.onRouteChanged() &&  <Home visible={true} config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Route path='/login' render={props => <Login config={this.props.config} {...props} />} />
                        <Route exact path='/newTemplate' render={props => this.onRouteChanged() &&  <NewTemplate config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Route path='/newTemplate/:templateToCopyKey' render={props => this.onRouteChanged() &&  <NewTemplate config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Route exact path='/selectTemplate' render={props => this.onRouteChanged() &&  <SelectTemplate config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Route path='/copyTemplate' render={props => this.onRouteChanged() &&  <CopyTemplate config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Route path='/editTemplate/:templateKey' render={props => this.onRouteChanged() &&  <EditTemplate config={this.props.config} {...props} permissions={this.ability} />}/>
                        <Redirect to="/home" />
                    </Switch>
                </HashRouter>

            </AbilityContext.Provider>
        );
    }
}
