import * as React from 'react';
import { Header } from '../../components/header';
import { DefaultButton, PrimaryButton, MessageBarType, IBreadcrumbItem, Dialog, DialogFooter, Icon, TooltipHost } from 'office-ui-fabric-react';
import { OpalDialog } from "../../components/dialog";
import { SelectToken } from "../../components/token/select";
import { Log, Util, Toast } from "@voxfp/opal_ui_common";
import { InsertSmartConditionDialog } from "../../components/conditionals/insert";
import { InsertSmartSwitchDialog } from "../../components/switches/insert";
import { InsertSmartTemplateDialog } from "../../components/smartTemplate/insert";
import { InsertSmartRepeatDialog } from "../../components/smartRepeat/insert";
import { TemplateService } from "../../services/templateService";
import { CommonUtils } from '../../utils/commonUtils';

export interface AppProps {
    title: string;
    config: object;
    match: any;
    history: any;
    permissions?: any;
}

export interface AppState {
    templateName: string;
    displayTokenDialog: boolean;
    displaySmartTemplateDialog: boolean,
    displaySmartRepeatDialog: boolean,
    displaySmartConditionDialog: boolean,
    displaySmartSwitchDialog: boolean,
    displayError: boolean;
    errorMessage: string;
    highlightedText: string;
    displayTemplateSavedDialog: boolean;
    playbookId: number;
    displayChangesWarning: boolean;
    leavePath: any;
    unsavedChanges: boolean;
    status: string;
    templateFamily: string,
    version: string
}

export class EditTemplate extends React.Component<AppProps, AppState> {

    public templateService: TemplateService = new TemplateService();
    public breadcrumbList: Array<IBreadcrumbItem> = [];
    public templateId = this.props.match.params.templateKey;
    public breadcrumbPreviousTitle = "";
    public breadcrumbPreviousLink = "";

    constructor(props, state) {
        super(props, state);
        const templateType = localStorage.getItem("templateType");

        if (templateType === "existing") {
            this.breadcrumbPreviousTitle = "Edit Template";
            this.breadcrumbPreviousLink = "/selectTemplate";
        }
        else if (templateType === "copy") {
            this.breadcrumbPreviousTitle = "Copy Template";
            this.breadcrumbPreviousLink = "/copyTemplate";
        }
        else if (templateType === "new") {
            this.breadcrumbPreviousTitle = "New Template";
            this.breadcrumbPreviousLink = "/newTemplate";
        }

        this.breadcrumbList = [
            {
                text: 'Home',
                key: 'Home',
                onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                    this.breadcrumbLeave("/home");
                }
            },
            {
                text: this.breadcrumbPreviousTitle,
                key: 'f1',
                onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                    this.breadcrumbLeave(this.breadcrumbPreviousLink);
                }
            },
            { text: 'Template', key: 'f2', isCurrentItem: true }
        ];

        this.state = {
            templateName: '',
            displayTokenDialog: false,
            displayTemplateSavedDialog: false,
            displaySmartTemplateDialog: false,
            displaySmartRepeatDialog: false,
            displaySmartConditionDialog: false,
            displaySmartSwitchDialog: false,
            displayError: false,
            errorMessage: null,
            highlightedText: '',
            playbookId: null,
            displayChangesWarning: false,
            leavePath: null,
            unsavedChanges: false,
            status: '',
            templateFamily: '',
            version: ''
        };
    }

    componentDidMount() {
        this.fetchTemplate();
        if (typeof Office !== 'undefined' && Office.context && Office.context.document) {
            Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, this.loadChanges.bind(this));
        }
    }

    loadChanges() {
        if (typeof Word !== 'undefined') {
            Word.run((context) => {
                let selection = context.document.getSelection();
                context.load(selection);
                return context.sync()
                    .then(() => {
                        if (!this.state.displayChangesWarning) {
                            this.setState({
                                highlightedText: selection.text,
                                unsavedChanges: true
                            });
                        }
                    }).catch(Log.error);
            });
        }
    }

    fetchTemplate() {
        return this.templateService.getTemplate(this.props.match.params.templateKey).then((template: any) => {
            this.setState({
                templateName: template.name,
                templateFamily: template.templateFamily.name,
                version: template.version,
                playbookId: template.templateFamily.playbook.id,
                status: template.currentRevision["workflow_state"]
            });
            return template;
        }).catch((error) => {
            Util.showToast(new Toast(error, MessageBarType.error));
        });
    }

    saveTemplate() {
        this.fetchTemplate();
        Util.startSpinning();
        let self = this;
        this.setState({ displayTemplateSavedDialog: false, displayError: false });
        if (typeof Word !== 'undefined') {
            Word.run(async (context) => {

                let body = context.document.body;
                let bodyOOXML = body.getOoxml();
                context.sync().then(() => {

                    this.templateService.saveTemplate({
                        id: self.props.match.params.templateKey,
                        ooxml: bodyOOXML.value
                    }).then(() => {
                        this.setState({
                            unsavedChanges: false
                        });
                        self.openTemplateSavedDialog();
                    }).catch((error) => {
                        Util.showToast(new Toast(error, MessageBarType.error));
                        self.setState({ errorMessage: error, displayError: true });
                    }).finally(() => {
                        Util.stopSpinning();
                    });
                }).catch(Log.error);
            });

        }
    }



    openTokenDialog() {
        this.resetDialogs();
        this.setState({
            displayTokenDialog: true
        });
    }

    openTemplateSavedDialog() {
        this.resetDialogs();
        this.setState({
            displayTemplateSavedDialog: true
        });
    }

    openSmartConditionDialog() {
        this.resetDialogs();
        this.setState({
            displaySmartConditionDialog: true
        });
    }

    openSmartSwitchDialog() {
        this.resetDialogs();
        this.setState({
            displaySmartSwitchDialog: true
        });
    }

    openSmartTemplateDialog() {
        this.resetDialogs();
        this.setState({
            displaySmartTemplateDialog: true
        });
    }

    openSmartRepeatDialog() {
        this.resetDialogs();
        this.setState({
            displaySmartRepeatDialog: true
        });
    }

    handleInsertToken() {
        Util.showToast(new Toast('Token inserted successfully.', MessageBarType.success));
    }

    handleInsertSmartCondition() {
        Util.showToast(new Toast('Conditional inserted successfully.', MessageBarType.success));
    }

    handleInsertSmartSwitch() {
        Util.showToast(new Toast('Switch inserted successfully.', MessageBarType.success));
    }

    handleInsertSmartTemplate() {
        Util.showToast(new Toast('Sub-Template inserted successfully.', MessageBarType.success));
    }

    handleInsertSmartRepeat() {
        Util.showToast(new Toast('Repeat inserted successfully.', MessageBarType.success));
    }

    breadcrumbLeave(path) {
        if (!this.state.unsavedChanges) {
            this.leavePage(path);
        }
        else {
            this.displayChangesWarningDialog(path);
        }
    }

    leavePage(path) {
        localStorage.removeItem("templateType");
        this.props.history.push(path);       
    }

    displayChangesWarningDialog(path) {
        this.setState({
            displayChangesWarning: true,
            leavePath: path
        });
    }

    hideChangesWarningDialog() {
        this.setState({
            displayChangesWarning: false
        });
    }

    componentWillUnmount() {
        localStorage.removeItem("templateType");
    }

    resetDialogs() {
        this.setState({
            displayTokenDialog: false,
            displaySmartConditionDialog: false,
            displaySmartSwitchDialog: false,
            displaySmartTemplateDialog: false,
            displaySmartRepeatDialog: false,
            displayTemplateSavedDialog: false,
            displayError: false,
            highlightedText: ''
        });
    }

    render() {
        
        return (

            <div className='ms-navigation__main'>
                <Header match={this.props.match} message='' history={this.props.history} breadcrumbLinks={this.breadcrumbList} unsavedChanges={this.state.unsavedChanges} permissions={this.props.permissions} />
                {this.state && this.state.templateName &&
                        <div className="flex pt1 px1 pb0 ms-font-m ms-fontColor-neutralPrimary">
                            <div className="flextop mb1">
                                <h2 className="mr05 mt0 mb1" style={{ display: "inline-block" }}>
                                    {this.state && this.state.templateName}
                                </h2>
                                {this.state && this.state.unsavedChanges && this.state.templateName && this.state.status === "Draft" &&
                                    <TooltipHost content="There are unsaved changes in your document.">
                                        <Icon
                                            iconName="Save"
                                            className="ms-fontColor-red"
                                            style={{ verticalAlign: "middle", marginBottom: "5px" }}
                                        >
                                        </Icon>
                                    </TooltipHost>
                                }
                                <br />
                                <strong>Template Family:</strong> {this.state.templateFamily}
                                <br />
                                <strong>Version:</strong> {this.state.version}, <strong>Status:</strong> {this.state.status}
                            </div>
                            <div className="flexcontent">
                                <DefaultButton
                                    text='Insert Token'
                                    iconProps={{ iconName: "FileCode" }}
                                    className="insert-button display-block"
                                    onClick={this.openTokenDialog.bind(this)}
                                />

                                <DefaultButton
                                    text='Insert Sub-template'
                                    iconProps={{ iconName: "FormLibrary" }}
                                    className="insert-button display-block"
                                    onClick={this.openSmartTemplateDialog.bind(this)}
                                />

                                <DefaultButton
                                    text='Insert Condition'
                                    iconProps={{ iconName: "Dom" }}
                                    className="insert-button display-block"
                                    onClick={this.openSmartConditionDialog.bind(this)}
                                />

                                <DefaultButton
                                    text='Insert Switch'
                                    iconProps={{ iconName: "Switch" }}
                                    className="insert-button display-block"
                                    onClick={this.openSmartSwitchDialog.bind(this)}
                                />

                                <DefaultButton
                                    text='Insert Repeat'
                                    iconProps={{ iconName: "RepeatAll" }}
                                    className="insert-button display-block mb2"
                                    onClick={this.openSmartRepeatDialog.bind(this)}
                                />
                            </div>
                            <div className="flexbottom pb1">
                                {this.state.status === "Draft" &&
                                    <PrimaryButton text='Save' iconProps={{ iconName: "Save" }} className="insert-button display-block mb0" onClick={this.saveTemplate.bind(this)} />
                                }
                            </div>
                        </div>

                }
                

                {this.state && this.state.displayTokenDialog &&
                    <SelectToken
                        title={'Insert Token'}
                        templateKey={this.props.match.params.templateKey}
                        playbookId={this.state.playbookId}
                        onInsertToken={this.handleInsertToken.bind(this)}
                        onCancel={() => this.resetDialogs()}
                    />
                }
                {this.state && this.state.displaySmartConditionDialog &&
                    <InsertSmartConditionDialog
                        title={'Insert Conditional'}
                        templateKey={this.props.match.params.templateKey}
                        playbookId={this.state.playbookId}
                        onInsertSmartCondition={this.handleInsertSmartCondition.bind(this)}
                        onCancel={() => this.resetDialogs()}
                    />
                }

                {this.state && this.state.displaySmartSwitchDialog &&
                    <InsertSmartSwitchDialog
                        title={'Insert Switch'}
                        templateKey={this.props.match.params.templateKey}
                        playbookId={this.state.playbookId}
                        onInsertSmartSwitch={this.handleInsertSmartSwitch.bind(this)}
                        onCancel={() => this.resetDialogs()}
                    />
                }

                {this.state && this.state.displaySmartTemplateDialog &&
                    <InsertSmartTemplateDialog
                        title={'Insert Sub-Template'}
                        templateId={this.props.match.params.templateKey}
                        onInsertSmartTemplate={this.handleInsertSmartTemplate.bind(this)}
                        onCancel={() => this.resetDialogs()}
                    />
                }

                {this.state && this.state.displaySmartRepeatDialog &&
                    <InsertSmartRepeatDialog
                        title={'Insert Repeat'}
                        playbookId={this.state.playbookId}
                        onInsertSmartRepeat={this.handleInsertSmartRepeat.bind(this)}
                        onCancel={() => this.resetDialogs()}
                    />
                }

                <Dialog
                    hidden={!this.state.displayChangesWarning}
                    onDismiss={this.hideChangesWarningDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Unsaved Changes',
                        subText: 'You have unsaved changes. Are you sure you want to leave this page?'
                    }}
                >
                    <DialogFooter>
                        <DefaultButton onClick={this.hideChangesWarningDialog.bind(this)} text='Stay' />
                        <PrimaryButton onClick={() => { this.leavePage(this.state.leavePath); }} text='Leave this page' />
                    </DialogFooter>
                </Dialog>

                <OpalDialog
                    text={'The template was saved successfully.'}
                    title={'Success'}
                    visible={this.state && this.state.displayTemplateSavedDialog}
                    always={() => this.resetDialogs()}
                />
                <OpalDialog
                    text={this.state.errorMessage}
                    title={'Error'}
                    visible={this.state && this.state.displayError}
                    always={() => this.resetDialogs()}
                />
            </div>
        );
    }

}
